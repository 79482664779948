import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {TimelineComponent} from './timeline/timeline.component';
import {AboutComponent} from './about/about.component';
import {StatistikaComponent} from './statistika/statistika.component';
import {ContactComponent} from './contact/contact.component';
import {HowToUseTimelineComponent} from './how-to-use-timeline/how-to-use-timeline.component';
import {TimelineFilterComponent} from './timeline-filter/timeline-filter.component';
import {EventDetailsComponent} from './event-details/event-details.component';
import {IzvorDetaljiComponent} from './izvor-detalji/izvor-detalji.component';
import {EventDetailsGuard} from './guards/event-details.guard';
import {QuestionaryGuard} from './guards/questionary.guard';
import {QuestionnaireModalComponent} from './questionary/questionnaire-modal.component';
import {QuestionnairePageComponent} from './questionnaire-page/questionnaire-page.component';

const routes: Routes = [
  {path: '', redirectTo: '/timeline/0', pathMatch: 'full'},
  {path: 'about', component: AboutComponent},
  {path: 'timeline/:eventId', component: TimelineComponent},
  {path: 'statistika', component: StatistikaComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'how-to-use-timeline', component: HowToUseTimelineComponent},
  {path: 'timeline-filter', component: TimelineFilterComponent},
  {path: 'event-detail/:eventID', component: EventDetailsComponent, canActivate: [EventDetailsGuard]},
  {path: 'source-detail', component: IzvorDetaljiComponent, canActivate: [ EventDetailsGuard]},
  {path: 'questionnaire', component: QuestionnairePageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
