import { Component, OnInit } from '@angular/core';
import {QuestionnaireModalComponent} from '../questionary/questionnaire-modal.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-questionnaire-page',
  templateUrl: './questionnaire-page.component.html',
  styleUrls: ['./questionnaire-page.component.css']
})
export class QuestionnairePageComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }


  public async openQuestionnaireDialog() {
    const dialogRef = this.dialog.open(QuestionnaireModalComponent, {
      width: '30%',
      data: {
        timelineEvent: null,
        selectedMediaSourceNum: null
      }
    });
    dialogRef.afterClosed().subscribe(() => console.log('DIALOG IS CLOSED'));
  }
}
