import {EventCategory} from './EventCategory';
import {MediaSource} from './MediaSource';
import {MediaTag} from './MediaTag';

export class TimelineEvent {
  id?: string;
  title?: string;
  description?: string;
  category?: EventCategory;
  date?: Date;
  daysFromBeginning?: number;
  isActive?: boolean;
  lane?: number;
  significance?: number;
  animation?: string;
  /*  media sources arrays */
  mediaSources?: MediaSource[];
  /*  Tagovi koji su pridruzeni vestima  */
  institucijaTags?: string[];
  licnostiTags?: string[];
  ostaloTags?: string[];
  siriTags?: string[];
  showEvent?: boolean;
  smrt = false;
  detailDataLoaded = false;

}
