import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EventCategory} from '../domain/EventCategory';
import {MediaTagGroup} from '../domain/MediaTagGroup';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {MediaTag} from '../domain/MediaTag';
import {TimelineEventService} from '../services/timeline-event.service';
import {filter, map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material';
import {FilterDataChanged, FilterDataChangedEvent, FilterType, TimelineFilterService} from '../services/timeline-filter.service';
import {ExampleHeaderComponent} from '../example-header/example-header.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateManagementService} from '../services/state-management.service';
import {FilterState} from '../shared/models/state.models';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-timeline-filter',
  templateUrl: './timeline-filter.component.html',
  styleUrls: ['./timeline-filter.component.css']
})
export class TimelineFilterComponent implements OnInit {

  @Input() totalNoOfEvents = 0;
  @Input() pickerStartDate;
  @Input() minDate;
  @Input() maxDate;

  @Output() filterChanged = new EventEmitter();

  filteredStartDate: Date;
  filteredEndDate: Date;
  filterEventName: string;
  oldFilterEventName: string;

  filterHasChanged: boolean;
  @Input() categories: EventCategory[];
  labelPosition = 'after';

  mediaTagGroups: Array<MediaTagGroup>;

  categoriesCtrl = new FormControl();
  mediaTagCtrl = new FormControl();
  filteredMediaTagGroups: Observable<MediaTagGroup[]>;
  filteredCategories: Observable<EventCategory[]>;
  filteredMediaTags: Observable<MediaTag[]>;

  allMediaTags: MediaTag[] = new Array();

  chosenTags: MediaTag[];
  chosenCategories: EventCategory[];

  zanimljivostiCat = new EventCategory('Zanimljivosti', '#df6d27', false, false);
  exampleHeader = ExampleHeaderComponent;

  filterTypEnum = FilterType;
  defaultStartDate = moment('01-01-1990', 'DD-MM-YYYY').toDate();
  defaultEndDate = moment('01-11-2003', 'DD-MM-YYYY').toDate();
  @ViewChild('filterByTagTrigger', {read: MatAutocompleteTrigger}) trigger: MatAutocompleteTrigger;
  @ViewChild('filterByRubrikaTrigger', {read: MatAutocompleteTrigger}) filterRubrikaTrigger: MatAutocompleteTrigger;

  constructor(private httpTimelineservice: TimelineEventService, private timelineFilterService: TimelineFilterService,
              public deviceDetector: DeviceDetectorService, private stateManagementService: StateManagementService,
              private router: Router) {
  }

  ngOnInit() {
    console.log('NgOnInit - TIMELINEFILTER', this.defaultStartDate, this.defaultEndDate);
    this.timelineFilterService.extrernalFilterDataChangedSubject.subscribe(
      fdce => {
        this.setExternalyChangedData(fdce);
      }
    );
    this.initData();
    this.reloadTheState();

  }

  initData() {
    this.initCategories();
    this.initAllMediaTags();
    this.chosenCategories = new Array();
    this.chosenTags = new Array();
    this.filteredCategories = this.categoriesCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this._filterCategories(state) : this.categories.slice())
      );
    this.filteredMediaTagGroups = this.mediaTagCtrl.valueChanges
      .pipe(
        startWith(''),
        map(mediaTag => mediaTag ? this.filterTagGroups(mediaTag) : this.mediaTagGroups.slice())
      );

  }

  initCategories() {
    this.categories = new Array();

    this.categories.push(new EventCategory('Politika', '#E32121', true));
    this.categories.push(new EventCategory('Ekonomija', '#DEC024', true));
    this.categories.push(new EventCategory('Društvo', '#1c25dd', true));
    this.categories.push(new EventCategory('Sport', '#28772a', true));
    // this.categories.push(new EventCategory('Statistika', '#6b2f75'));
    this.categories.push(this.zanimljivostiCat);
    this.categories.push(new EventCategory('Kultura', '#938b80', true));
  }

  resetAllFilters() {
    this.categories.forEach(categ => categ.selected = false);
  }

  initAllMediaTags() {
    this.httpTimelineservice.getAllTags().subscribe(data => {
      this.allMediaTags = new Array();
      this.mediaTagGroups = new Array<MediaTagGroup>();
      Object.keys(data).forEach(key => {
        const obj: any = data[key];
        const newMediaTag = new MediaTag();
        newMediaTag.id = key;
        newMediaTag.tagType = obj['ColumnValues'][0];
        newMediaTag.tagName = obj['ColumnValues'][1];
        this.allMediaTags.push(newMediaTag);

        // if (newMediaTag.tagName ) console.log('media tag:' + newMediaTag.tagType + ', mediaTypeValue=' + newMediaTag.tagName);
        if (newMediaTag.tagType && newMediaTag.tagType.trim() !== '' && newMediaTag.tagName && newMediaTag.tagName.trim() !== '') {

          // console.log('media tag:' + newMediaTag.tagType + ', mediaTypeValue=' + newMediaTag.tagName);
          const ind = this.mediaTagGroups.findIndex(mediaTagGroup => {
            // console.log(mediaTagGroup.mediaTagTypeName + '===' + newMediaTag.tagType);
            return mediaTagGroup.mediaTagTypeName === newMediaTag.tagType;
          });
          // console.log('Grupa za media tag:' + newMediaTag.tagType + ', je na mestu:' + ind);
          if (ind >= 0) {
            this.mediaTagGroups[ind].groupTags.push(newMediaTag);
            // console.log('pronasao sam Grupu za TAg:' + JSON.stringify(newMediaTag));
          } else {
            const newMediaTagGroup = new MediaTagGroup();
            newMediaTagGroup.mediaTagTypeName = newMediaTag.tagType;
            newMediaTagGroup.groupTags = new Array();
            newMediaTagGroup.groupTags.push(newMediaTag);
            this.mediaTagGroups.push(newMediaTagGroup);
          }
        }
      });
    });


  }

  private _filterCategories(value: any): EventCategory[] {
    console.log('value:' + value);
    if (value instanceof Object) {
      return new Array();
    }
    const filterValue = value.toLowerCase();
    return this.categories.filter(cat => cat.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterMediaTags(value: any): MediaTag[] {
    console.log('value:' + value);
    if (value instanceof Object) {
      return new Array();
    }
    const filterValue = value.toLowerCase();
    return this.allMediaTags.filter(tag => tag.tagName.toLowerCase().indexOf(filterValue) === 0);
  }

  filterTagGroups(value: string): MediaTagGroup[] {
    console.log('Filtriram grupa: ' + this.mediaTagGroups.length + ', vrednost:' + value);
    if (value) {
      return this.mediaTagGroups.map(tagGroup => {
        const newMedGroup = new MediaTagGroup();
        newMedGroup.mediaTagTypeName = tagGroup.mediaTagTypeName;
        newMedGroup.groupTags = this._filterTags(tagGroup, value);
        return newMedGroup;
      }).filter(medGrp => medGrp.groupTags.length > 0);

    }

    this.filterHasChanged = true;
    return this.mediaTagGroups.slice();

  }

  _filterTags(tagGroup: MediaTagGroup, value: string): MediaTag[] {

    this.filterHasChanged = true;
    if (value) {
      const val = String(value).toLowerCase();
      return tagGroup.groupTags.filter(mediaTag =>
        mediaTag.tagName.toLowerCase().indexOf(val) >= 0 && this.chosenTags.findIndex(mt => mt.id === mediaTag.id) < 0);
    } else {
      return tagGroup.groupTags.slice();
    }

  }


  filterByTagFocus() {
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  displayFnTags(tag: MediaTag): string {
    return '';
  }


  tagSelected(event: MatAutocompleteSelectedEvent) {
    console.log('selected value:' + event.option.value);
    this.chosenTags.push(event.option.value);
    const ind = this.allMediaTags.findIndex(cat => cat === event.option.value);
    console.log('ind:' + ind);
    if (ind >= 0) {
      console.log('TAG:' + JSON.stringify(this.allMediaTags[ind]));
      this.allMediaTags.splice(ind, 1);
    }
    this.filterHasChanged = true;
    //this.filterAllTimelineEvents();
  }

  filterAllTimelineEvents() {
    this.applyFilter(
      {
        chosenCategories: this.chosenCategories,
        filteredStartDate: this.filteredStartDate,
        filteredEndDate: this.filteredEndDate,
        filterEventName: this.filterEventName,
        chosenTags: this.chosenTags
      }
    );
  }

  applyFilter(filterData: any) {
    this.timelineFilterService.filterDataHasChanged(filterData);
    this.preserveTheState();
    this.filterChanged.emit({event: 'filterChanged'});
    this.filterHasChanged = false;
    this.timelineFilterService.filterApplyed.next({});
    console.log('device desktop: ', this.deviceDetector.isDesktop());
    if (!this.deviceDetector.isDesktop()) {
      this.router.navigateByUrl('/timeline/0');
    }


  }


  categorieSelected(event: any) {
    console.log('Selektovana je neka kategorija:');
    /*  TODO: Osmisliti bolji algoritam za ovo.   */
    if (this.zanimljivostiCat.selected) {
      //this.allTimelineEvents = [...this.allTimelineeventsTemp];
      this.zanimljivostiCat.selected = false;
    }
    this.chosenCategories = [...this.categories.filter(cat => cat.selected)];
    this.filterHasChanged = true;
    //this.filterAllTimelineEvents();
  }

  setExternalyChangedData(fdce: FilterDataChanged) {
    console.log('EXTERNAL DATA EVENT:', fdce.changedData);
    switch (fdce.changeEvent) {
      case FilterDataChangedEvent.DATE_CHANGED:
        this.filteredStartDate = fdce.changedData.filteredStartDate;
        this.filteredEndDate = fdce.changedData.filteredEndDate;
        this.filterHasChanged = true;
        break;
      case FilterDataChangedEvent.TAG_ADDED:
        const indAllTags = this.allMediaTags.findIndex(mediaTag => mediaTag.tagName === fdce.changedData.tag);
        if (indAllTags < 0) {
          console.log('Naziv taga nije pronađen u listi tagova');
          return;
        }
        const ind = this.chosenTags.findIndex(tag => tag.tagName === fdce.changedData.tag);
        console.log('ind TAG:', ind, fdce.changedData.tag);
        if (ind < 0) {
          this.chosenTags.push(this.allMediaTags[indAllTags]);
        }
        this.filterHasChanged = true;
        break;
      default:
        console.log('NOTHING CHANGED ON EXTERNAL DATA EVENT:', fdce.changeEvent);
    }
  }

  filterAllTimelineEventsbyName() {
    console.log(this.filterEventName + ' = ' + this.oldFilterEventName);
    if (this.filterEventName !== this.oldFilterEventName) {
      this.oldFilterEventName = this.filterEventName;
      //this.filterAllTimelineEvents();
    }
    this.filterHasChanged = true;


  }

  dateChanged() {
    this.filterHasChanged = true;
  }


  private reloadTheState() {
    const filterState: FilterState = this.stateManagementService.getState('timelinefilter');
    console.log('state:', filterState);
    if (filterState) {

      this.minDate = filterState.minDate;
      this.maxDate = filterState.maxDate;
      this.totalNoOfEvents = filterState.totalNoOfEvents;
      this.filteredStartDate = filterState.filteredStartDate;
      this.filteredEndDate = filterState.filteredEndDate;
      this.categories = filterState.categories;
      this.labelPosition = filterState.labelPosition;
      this.filterEventName = filterState.filterEventName;
      // this.filteredMediaTagGroups = filterState.filteredMediaTagGroups;
      this.chosenTags = filterState.chosenTags;
      this.chosenCategories = filterState.chosenCategories;
    }

  }

  private preserveTheState() {
    const filterState: FilterState = {
      minDate: this.minDate,
      maxDate: this.maxDate,
      totalNoOfEvents: this.totalNoOfEvents,
      filteredStartDate: this.filteredStartDate,
      filteredEndDate: this.filteredEndDate,
      categories: this.categories,
      labelPosition: this.labelPosition,
      filterEventName: this.filterEventName,
      // this.filteredMediaTagGroups = this.filteredMediaTagGroups;
      chosenTags: this.chosenTags,
      chosenCategories: this.chosenCategories
    };
    console.log('PRESERVING THE STATE', filterState);
    this.stateManagementService.preserveState('timelinefilter', filterState);
  }

  removeSelectedTagFromFilter(index: number) {
    const tag = this.chosenTags[index];
    this.allMediaTags.push(this.chosenTags.splice(index, 1)[0]);
    this.filterHasChanged = true;
    //this.filterAllTimelineEvents();
  }

  removeOneFilter(filterType: FilterType) {
    switch (filterType) {
      case FilterType.TAG:
        this.allMediaTags.push(...this.chosenTags);
        this.chosenTags = [];
        break;
      case FilterType.RUBRIKA:
        this.categories.forEach(cat => cat.selected = true);
        break;
      case FilterType.NAZIV_DOGADJAJA:
        this.filterEventName = '';
        break;
      case FilterType.DATUM:
        this.filteredStartDate = null;
        this.filteredEndDate = null;
        break;
    }
  }

  applyOneFilter(filterType: FilterType) {
    const filterData = {
      chosenCategories: [],
      filteredStartDate: null,
      filteredEndDate: null,
      filterEventName: '',
      chosenTags: []
    };
    switch (filterType) {
      case FilterType.TAG:
        filterData.chosenTags = [...this.chosenTags];
        break;
      case FilterType.RUBRIKA:
        filterData.chosenCategories = this.categories.filter(cat => cat.selected === true);
        break;
      case FilterType.NAZIV_DOGADJAJA:
        filterData.filterEventName = this.filterEventName;
        break;
      case FilterType.DATUM:
        filterData.filteredStartDate = this.filteredStartDate;
        filterData.filteredEndDate = this.filteredEndDate;
        break;
    }
    this.applyFilter(filterData);
  }

  removeAllFilters() {
    this.allMediaTags.push(...this.chosenTags);
    this.chosenTags = [];
    this.categories.forEach(cat => cat.selected = true);
    this.filterEventName = '';
    this.filteredStartDate = null;
    this.filteredEndDate = null;
    this.filterAllTimelineEvents();
  }

}
