import {Component, Input, OnInit} from '@angular/core';
import {TimelineEvent} from '../domain/TimelineEvent';
import {MobileViewMode} from '../shared/enums/enums';
import {DialogsComponent} from '../dialogs/dialogs.component';
import {MatDialog} from '@angular/material';
import {DeviceDetectorService} from 'ngx-device-detector';
import {OnDayEvent} from '../services/timeline-util.service';
import {MediaSource} from '../domain/MediaSource';
import {FilterDataChanged, FilterDataChangedEvent, TimelineFilterService} from '../services/timeline-filter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TimelineEventService} from '../services/timeline-event.service';
import {StateManagementService} from '../services/state-management.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {

  @Input() selectedEvent: TimelineEvent;
  @Input() selectedEventID: string;
  @Input() selectedEventIndex: number;
  @Input() selectedOnDayEvent: OnDayEvent;
  @Input() landingPage = true;
  @Input() activeMediaSourceNum: number;
  mobileViewMode: MobileViewMode;

  constructor(public dialog: MatDialog, public deviceDetector: DeviceDetectorService,
              private timelineFilterService: TimelineFilterService, private activatedRoute: ActivatedRoute,
              private router: Router,
              private httpTimelineservice: TimelineEventService, private stateManagementService: StateManagementService) {
  }

  ngOnInit() {
    this.selectedEvent = this.stateManagementService.getState('selected_event');
    if (!this.selectedEvent) {
      this.selectedEventID = this.activatedRoute.snapshot.paramMap.get('eventID');
      this.selectedEvent = new TimelineEvent();
      this.selectedEvent.id = this.selectedEventID;
    }
    this.vratiPodatkeIzabranogDogadjaja(this.selectedEvent);

  }


  openDialog(selectedEvent: TimelineEvent, mediaSourceNum: number) {
    console.log('OPENING A DIALOG!!!');
    this.activeMediaSourceNum = mediaSourceNum;
    this.stateManagementService.preserveState('selected_event', selectedEvent);
    this.stateManagementService.preserveState('ACTIVE_MEDIA_SOURCE_NUM', this.activeMediaSourceNum);
    this.mobileViewMode = MobileViewMode.SOURCE_DETAILS;
    if (!this.deviceDetector.isMobile()) {
      const dialogRef = this.dialog.open(DialogsComponent, {
        width: '90%',
        height: '90%',
        data: {
          timelineEvent: selectedEvent,
          selectedMediaSourceNum: mediaSourceNum
        }
      });

    } else {
      this.router.navigateByUrl('source-detail');
    }
  }


  mediaSourceLoaded(mediaSource: MediaSource) {
    mediaSource.imageLoaded = true;
    console.log('Image loaded for ' + mediaSource.id);
  }

  addTagToFilter(tag: string) {
    console.log('TAG SE DODAJE!' + tag);
    this.timelineFilterService.externalFilterDataHasChanged(
      new FilterDataChanged(
        FilterDataChangedEvent.TAG_ADDED,
        {
          tag: tag
        })
    );
  }

  vratiPodatkeIzabranogDogadjaja(selEvent: TimelineEvent) {
    this.httpTimelineservice.getTimelineEvent(selEvent.id).subscribe(data => {
      Object.keys(data).forEach(key => {
        const obj: any = data[key];
        // console.log('dogadjaj detalji:' + JSON.stringify(obj['Items']));
        this.fillTimeLineEventDetails(selEvent, obj['Items']);
        // Object.keys(obj).forEach(k => console.log('key:' + k + ', values:' + obj[k]));
      });
    });
  }

  fillTimeLineEventDetails(timelineEvent: TimelineEvent, eventValuesJSON: any) {
    timelineEvent.description = this.getSingleValue(eventValuesJSON['opis']);
    timelineEvent.title = this.getSingleValue(eventValuesJSON['naziv']);
    timelineEvent.smrt = this.getSingleValue(eventValuesJSON['smrt']) === 'DA' ? true : false;
    console.log('description:' + timelineEvent.description);
    const izvoriValues: string[] = this.getArrayOfValues(eventValuesJSON['izvori']);
    const medijiValues: string[] = this.getArrayOfValues(eventValuesJSON['mediji']);

    timelineEvent.licnostiTags = this.getArrayOfValues(eventValuesJSON['licnost']);
    timelineEvent.institucijaTags = this.getArrayOfValues(eventValuesJSON['institucija']);
    timelineEvent.ostaloTags = this.getArrayOfValues(eventValuesJSON['ostalo']);
    timelineEvent.siriTags = this.getArrayOfValues(eventValuesJSON['siri']);

    this.checkArrays(timelineEvent);

    console.log('izvori values:' + izvoriValues);
    // ovde bi trebalo proveriti da li je vec loadovana ako jeste da se ne radi ponovo.
    timelineEvent.mediaSources = new Array();
    izvoriValues.forEach((izvor, ind) => {
      const newMediaSource: MediaSource = new MediaSource();
      newMediaSource.id = izvor;
      newMediaSource.sourceInstitutionName = medijiValues[ind];
      timelineEvent.mediaSources.push(newMediaSource);
    });
    this.ucitajPodatkeZaSveIzvore(timelineEvent);
  }

  getSingleValue(eventAttValuesJSON: any): any {
    if (!eventAttValuesJSON || !eventAttValuesJSON['Values'] || eventAttValuesJSON['Values'].length === 0) {
      return 'NEPOZNATO';
    }

    const obj = eventAttValuesJSON['Values'];
    return obj[0];
  }

  getArrayOfValues(eventAttValuesJSON: any): any[] {
    const valuesArray = new Array<any>();
    const obj = eventAttValuesJSON['Values'];
    return obj;
  }

  checkArrays(timelineEvent: TimelineEvent) {
    /*  Oni salju niz sa jednim praznim podatkom ako ne postoji nista, pa to mora da se sredi.  */
    timelineEvent.institucijaTags = this.vratiSredjenNiz(timelineEvent.institucijaTags);
    timelineEvent.licnostiTags = this.vratiSredjenNiz(timelineEvent.licnostiTags);
    timelineEvent.ostaloTags = this.vratiSredjenNiz(timelineEvent.ostaloTags);
    timelineEvent.siriTags = this.vratiSredjenNiz(timelineEvent.siriTags);
  }

  vratiSredjenNiz(tagsArray: string[]): string[] {

    const newArray = new Array();
    tagsArray.forEach(tag => {
      if (tag && tag.trim().length > 0) {
        newArray.push(tag);
      }
    });

    return newArray;

  }

  ucitajPodatkeZaSveIzvore(timelineEvent: TimelineEvent) {
    console.log('Ucitavam podatke za sve izvore');
    timelineEvent.mediaSources.forEach(mediaSource => {
      this.ucitajDetaljnePodatkeOIzvoru(mediaSource);
    });
  }

  ucitajDetaljnePodatkeOIzvoru(mediaSource: MediaSource) {
    if (mediaSource.id == 'Nema izvora') {
      return;
    }
    this.httpTimelineservice.getMediaSourcedetails(mediaSource.id).subscribe(data => {
      Object.keys(data).forEach(key => {
        const obj: any = data[key];
        // console.log('dogadjaj detalji:' + JSON.stringify(obj['Items']));
        this.fillMediaSourceDetails(mediaSource, obj['Items']);
        // Object.keys(obj).forEach(k => console.log('key:' + k + ', values:' + obj[k]));
      });
    });
  }

  fillMediaSourceDetails(mediaSource: MediaSource, mediaSourceDetailsValues: any) {
    mediaSource.fileName = this.getSingleValue(mediaSourceDetailsValues['filename']);
    mediaSource.fullLink = this.getSingleValue(mediaSourceDetailsValues['link']);
    mediaSource.tip = this.getSingleValue(mediaSourceDetailsValues['tip']);
    mediaSource.thumbLink = this.getSingleValue(mediaSourceDetailsValues['link_tb']);
    mediaSource.thumbLink = mediaSource.thumbLink.replace(' ', '%20');
    mediaSource.autor = this.getSingleValue(mediaSourceDetailsValues['autor']);

    if (mediaSource.fullLink.toLowerCase().startsWith('Gre')) {
      mediaSource.wrongLink = true;
    }
    mediaSource.sourceInstitutionName = this.getSingleValue(mediaSourceDetailsValues['medij']);
    mediaSource.newLink = mediaSource.fullLink.replace(' ', '%20');
    mediaSource.sanitizedLink = mediaSource.fullLink.replace(' ', '%20');
    // console.log('full link:' + mediaSource.fullLink);
    // console.log('sanitized link:' + mediaSource.sanitizedLink);
    mediaSource.dataloaded = true;
    if (mediaSource.tip !== 'Audio') {
      mediaSource.sourceType = this.getSourceTypeFromFileName(mediaSource.fileName);
    } else {
      mediaSource.sourceType = 'AUDIO';
    }
  }

  getSourceTypeFromFileName(fileName: string): string {
    const ind = fileName.lastIndexOf('.');
    const fileExtension = fileName.substr(ind + 1);
    console.log('file:' + fileName + '; file ext:' + fileExtension);
    switch (fileExtension.toLowerCase()) {

      case 'jpg':
      case 'png':
      case 'gif':
        return 'IMAGE';
      case 'mp4':
      case 'mov':
      case 'wmv':
      case 'avi':
        return 'VIDEO';
      case 'pdf':
        return 'PDF';
      case 'mp3':
        return 'AUDIO';
      default:
        return 'NEPOZNATO';

    }
  }

  vratiUrlTekst(mediaSource: MediaSource): string {
    return 'url(\"' + mediaSource.fullLink + '\")';
  }
}
