import {Injectable} from '@angular/core';
import {TimelineEvent} from '../domain/TimelineEvent';
import * as moment from 'moment';

export const MAX_DAYS_DIFFERENCE = 3;


@Injectable({
  providedIn: 'root'
})
export class TimelineUtilService {

  constructor() {
  }

  popoversDisplayed = false;

  getOnTodaysDateEvents(allEvents: TimelineEvent[]): OnDayEvent[] {
    const onDayEvents: OnDayEvent[] = [];

    const now = new Date();
    if (allEvents) {
      allEvents.forEach(evt => {
        const dayDifference = this.numberOfDaysDifference(evt.date, now);
        if (dayDifference < MAX_DAYS_DIFFERENCE) {
          onDayEvents.push(new OnDayEvent(evt, dayDifference));
        }
      });
    }

    console.log('Izdvojeno je :' + onDayEvents.length);
    if (onDayEvents && onDayEvents.length > 0) {
      onDayEvents.sort(
        (a, b) => a.noOfDaysDifference - b.noOfDaysDifference
      );

      let lastRelevantEvtIndex = 0;
      for (let i = 1; i < onDayEvents.length; i++) {
        if (onDayEvents[0].noOfDaysDifference < onDayEvents[i].noOfDaysDifference) {
          lastRelevantEvtIndex = i - 1;
          break;
        }
      }
      if (lastRelevantEvtIndex < onDayEvents.length - 1) {
        onDayEvents.splice(lastRelevantEvtIndex + 1);
      }
      onDayEvents.sort((a, b) => b.timelineEvent.significance - a.timelineEvent.significance);
      let firstDiffSigevt = 1;
      onDayEvents.forEach((onDayEv, i, lst) => {
          if (firstDiffSigevt === 1 && onDayEv.timelineEvent.significance > lst[0].timelineEvent.significance) {
            firstDiffSigevt = i;
            return;
          }
        }
      );
      onDayEvents.splice(firstDiffSigevt);


    }
    return onDayEvents;
  }

  /* Ova metoda vraca broj dana razlike NE UZIMAJUCI U OBZIR GODINU... samo dan i mesec */
  numberOfDaysDifference(firstDate: Date, secondDate: Date): number {

    // console.log('string date:' + (secondDate.getUTCDate()) + '-' + (secondDate.getUTCMonth() + 1) + '-' + 2019);
    firstDate = moment(firstDate.getUTCDate() + '-' + (firstDate.getMonth() + 1) + '-' + 2019, 'DD-MM-YYYY').toDate();
    secondDate = moment((secondDate.getUTCDate()) + '-' + (secondDate.getUTCMonth() + 1) + '-' + 2019, 'DD-MM-YYYY').toDate();
    // console.log(JSON.stringify(firstDate) + ' ==== ' + JSON.stringify(secondDate));
    return Math.abs(this.inBetween(firstDate, secondDate));
  }


  inBetween(date1: Date, date2: Date) {
// Get 1 day in milliseconds
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
      console.log('NESTO NIJE DATUM:' + date1 + ', date2:' + date2);
      return 10;
    }
    const one_day = 1000 * 60 * 60 * 24;

// Convert both dates to milliseconds
    const date1_ms = date1.getTime();
    const date2_ms = date2.getTime();

// Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms;

// Convert back to days and return
    return Math.round(difference_ms / one_day);
  }
}


export class OnDayEvent {

  timelineEvent: TimelineEvent;
  noOfDaysDifference: number;

  constructor(timelineEvent: TimelineEvent, noOfDaysDifference: number) {
    this.timelineEvent = timelineEvent;
    this.noOfDaysDifference = noOfDaysDifference;
  }
}
