import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {MovableModule} from './movable/movable.module';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import {MovableDirective} from './movable/movable.directive';

import {
  MatAutocompleteModule, MatBottomSheetModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule, MatListModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelect,
  MatSelectModule, MatSidenavModule,
  MatSliderModule,
  MatTabsModule, MatToolbar, MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {MatCardModule} from '@angular/material/card';
import {MyDirectivesModule} from './my-directives/my-directives.module';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { DialogsComponent } from './dialogs/dialogs.component';
import {MatBadgeModule} from '@angular/material/badge';
import {NgModule} from '@angular/core';
import {AboutModule} from './about/about.module';
import {ShareButtonsModule} from '@ngx-share/buttons';
import {RouterModule, Routes} from '@angular/router';
import { TimelineComponent } from './timeline/timeline.component';
import {AppRoutingModule} from './app-routing.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TeaserComponent } from './teaser/teaser.component';
import { YearlyEventsComponent } from './yearly-events/yearly-events.component';
import { QuestionnaireModalComponent } from './questionary/questionnaire-modal.component';
import { ExampleHeaderComponent } from './example-header/example-header.component';
import { StatistikaComponent } from './statistika/statistika.component';
import { ContactComponent } from './contact/contact.component';
import { HowToUseTimelineComponent } from './how-to-use-timeline/how-to-use-timeline.component';
import {DeviceDetectorModule} from 'ngx-device-detector';
import { TimelineFilterComponent } from './timeline-filter/timeline-filter.component';
import { VerticalTimelineComponent } from './vertical-timeline/vertical-timeline.component';
import { IzvorDetaljiComponent } from './izvor-detalji/izvor-detalji.component';
import {PatchedGestureConfig} from './config/patched-gesture-config';
import { BottomSheetFilterComponent } from './bottom-sheet-filter/bottom-sheet-filter.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { QuestionnairePageComponent } from './questionnaire-page/questionnaire-page.component';
//
// const appRoutes: Routes = [
//   { path: '',  component: AppComponent},
//   { path: 'specEvent/:eventName/:eventId',  component: AppComponent},
//   // ,
//   // { path: '**', component: PageNotFoundComponent }
// ];


@NgModule({
  declarations: [
    AppComponent,
    DialogsComponent,
    DialogsComponent,
    TimelineComponent,
    TeaserComponent,
    YearlyEventsComponent,
    QuestionnaireModalComponent,
    ExampleHeaderComponent,
    StatistikaComponent,
    ContactComponent,
    HowToUseTimelineComponent,
    TimelineFilterComponent,
    VerticalTimelineComponent,
    IzvorDetaljiComponent,
    BottomSheetFilterComponent,
    EventDetailsComponent,
    QuestionnairePageComponent
  ],
  imports: [
    BrowserModule, MovableModule, MatAutocompleteModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatTabsModule,
    MatExpansionModule, BrowserAnimationsModule, HttpClientModule, MatBottomSheetModule,
    MatCardModule, MatGridListModule, MyDirectivesModule, NgxExtendedPdfViewerModule,
    VgCoreModule, VgControlsModule, MatIconModule, MatButtonModule, MatRadioModule, PdfViewerModule,
    MatDialogModule, MatBadgeModule, MatSliderModule,
    FormsModule, MatProgressSpinnerModule, MatTooltipModule, MatDatepickerModule, MatNativeDateModule,  ShareButtonsModule.forRoot(),
    AboutModule, MatCheckboxModule, MatSelectModule, MatSidenavModule, MatToolbarModule, MatListModule,
    AppRoutingModule, DeviceDetectorModule.forRoot(),
    PopoverModule.forRoot(),
    PdfViewerModule
    // , RouterModule.forRoot(appRoutes, {enableTracing: true})
  ],
  entryComponents: [DialogsComponent, QuestionnaireModalComponent, ExampleHeaderComponent, BottomSheetFilterComponent],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: PatchedGestureConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
