import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SiteOrigin} from '../shared/enums/enums';
import {QuestionaryModel} from '../domain/QuestionaryModel';
import {RazlogValues} from '../domain/enums';

@Injectable({
  providedIn: 'root'
})
export class TimelineEventService {


  // 'http://web.arhiv.rs/develop/timeline.nsf/';
  endpoint = 'http://116.202.32.48/Develop/Timeline.nsf/';
  endpointQuestionary = 'http://web.arhiv.rs/Develop/Timelineq.nsf/putQuerry?OpenAgent';

  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }), withCredentials: true
  };

  constructor(private http: HttpClient) {
  }


  setEndpointPathByUrl(url: string): SiteOrigin {
    if (url && url.indexOf('cacak') >= 0) {
      //http://109.93.199.103:85
      //http://116.202.32.48/Develop/TimelineCC.nsf/
      //http://116.202.32.48/Develop/Timeline.nsf/
      this.endpoint = 'http://109.93.199.103:85/Develop/TimelineCC.nsf/';
      return SiteOrigin.CACAK;
    } else {
      this.endpoint = 'http://109.93.199.103:85/Develop/Timeline.nsf/';
      return SiteOrigin.SRBIJA;
    }
  }

  getAllTimelineEvents(): Observable<any> {
    console.log('requesting data from: ' + this.endpoint + 'getview?OpenAgent');
    return this.http.get<any[]>(this.endpoint + 'getview?OpenAgent').pipe(
      catchError(this.handleError('getAllTimelineEvents', []))
    );
  }

  getTimelineEvent(eventId: string): Observable<any> {
    console.log('requesting data from: ' + this.endpoint + 'getdoc?OpenAgent&' + eventId);
    return this.http.get<any[]>(this.endpoint + 'getdoc?OpenAgent&' + eventId).pipe(
      catchError(this.handleError('getTimelineEvent', []))
    );
  }

  /*  Ovo je moglo da se podvede pod istu metodu kao i malo pre ali sam razdvojio zbog citljivosti.  */
  getMediaSourcedetails(mediaSourceId: string): Observable<any> {
    console.log('requesting data from media source: ' + this.endpoint + 'getdoc?OpenAgent&' + mediaSourceId);
    return this.http.get<any[]>(this.endpoint + 'getdoc?OpenAgent&' + mediaSourceId).pipe(
      catchError(this.handleError('getTimelineEvent', []))
    );
  }

  getAllTags(): Observable<any> {
    return this.http.get<any[]>(this.endpoint + 'getview?OpenAgent&tag').pipe(
      catchError(this.handleError('getTimelineEvent', []))
    );
  }

  // web.arhiv.rs/Develop/Timelineq.nsf/putQuerry?OpenAgen
  // t&godine=test&razlog=test&medij=test&problemi=test
  putQuestionaryResults(questionary: QuestionaryModel): Observable<any> {

    let params = new HttpParams()
      .set('pol', questionary.pol)
      .set('godine', questionary.godine)
      .set('razlog', questionary.razlog.toString())
      .set('medij', questionary.medij)
      .set('problemi', questionary.problemi)
      .set('email', questionary.email);

    if (Number(questionary.razlog) === RazlogValues.Novinarsko_istrazivanje) {
      params = params.set('medijskiMaterijalKvalitetan', questionary.medijskiMaterijalKvalitetan)
        .set('daLiBisteKoristiliMaterijal', questionary.daLiBisteKoristiliMaterijal);
    }

    console.log('params:', params);
    return this.http.get<any>(this.endpointQuestionary, {observe: 'response', params: params, responseType: 'text' as 'json'})
      .pipe(
        catchError(this.handleError('PUT QUESTIONARY', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
