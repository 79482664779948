import {TimelineEvent} from '../TimelineEvent';

export class YearEvent {

  year: number;
  listOfYearEvents: TimelineEvent[];

  constructor(year: number) {
    this.year = year;
    this.listOfYearEvents = new Array();
  }


}
