import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistika',
  templateUrl: './statistika.component.html',
  styleUrls: ['./statistika.component.css']
})
export class StatistikaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
