import { Directive, EventEmitter, ElementRef, Output, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';


@Directive({ selector: '[loadImage]' })
export class OnLoadDirective implements AfterViewInit {
  @Output() imageLoaded: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(private el: ElementRef){}

  ngAfterViewInit() {
    Observable.create(window.getComputedStyle(this.el.nativeElement).backgroundImage).subscribe(style => {
      if (style) {
        this.imageLoaded.emit(true);
      }
    });
  }

}
