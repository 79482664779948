import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateManagementService {

  states: { [id: string]: any; };

  constructor() {
    this.states = {};
  }

  public preserveState(key: string, stateObject: any) {
    this.states[key] = stateObject;
  }

  public getState(key: string) {
    return this.states[key];
  }
}
