 import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appMovable]'
})
export class MovableDirective {

  @Output() contentMoved: EventEmitter<any> = new EventEmitter();
  @HostBinding('class.movable') movable = true;

  @HostBinding('style.left') left: string;

  private moving = false;
  private clientX =  0;
  private clientY =  0;

  // constructor(private el: ElementRef, private renderer: Renderer2) {
  //   //this.renderer.`setElementClass(this.el.nativeElement, 'my-highlight', true);
  // }

  @HostListener('pointerdown', ['$event']) onPointerDown(event: PointerEvent): void{
    // console.log('pointer down!!' + event.clientX + ', left=' );  //+ this.el.nativeElement.style.left);
    this.moving = true;
    this.clientX = event.clientX;
    this.clientY = event.clientY;
  }
  @HostListener('document:pointermove', ['$event']) onPointerMove(event: PointerEvent): void {
    if (this.moving) {
      // console.log('pointer move!!!!' + event.clientY + ', this.clientY=', this.clientY );
      // console.log('movementy' + event.movementY + ', this.offset=', event.offsetY);
      const moveIncrement = event.clientX - this.clientX;
      const verticalIncrement = event.clientY - this.clientY;
      this.contentMoved.emit({ moveIncrement: moveIncrement, verticalIncrement: verticalIncrement });
      this.clientX = event.clientX;
      this.clientY = event.clientY;
      // event.stopPropagation();
    }
  }

  @HostListener('document:pointerup', ['$event']) onPointerUp(event: PointerEvent): void {
    if (this.moving) {
      this.moving = false;
    }
    // console.log('pointer UP!!!!.' + event.clientY);
  }
}
