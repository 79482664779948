import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, keyframes, transition, trigger} from '@angular/animations';
import * as kf from '../keyframes';
import {
  MatAccordion,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatDialog,
  MatExpansionPanel,
  MatIconRegistry
} from '@angular/material';
import {TimelineEvent} from '../domain/TimelineEvent';
import {YearEvent} from '../domain/view/YearEvent';
import {EventCategory} from '../domain/EventCategory';
import {MediaTag} from '../domain/MediaTag';
import * as moment from 'moment';
import {LineInfo} from '../domain/LineInfo';
import {FormControl} from '@angular/forms';
import {combineLatest, Observable, timer} from 'rxjs';
import {MediaTagGroup} from '../domain/MediaTagGroup';
import {TimelineEventService} from '../services/timeline-event.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {debounce, debounceTime, map, startWith} from 'rxjs/operators';
import {MediaSource} from '../domain/MediaSource';
import {DialogsComponent} from '../dialogs/dialogs.component';
import {OnDayEvent, TimelineUtilService} from '../services/timeline-util.service';
import {QuestionnaireModalComponent} from '../questionary/questionnaire-modal.component';
import {ExampleHeaderComponent} from '../example-header/example-header.component';
import {SignificanseEventInfo} from '../domain/SignificanseEventInfo';
import {MobileViewMode} from '../shared/enums/enums';
import {DeviceDetectorService} from 'ngx-device-detector';
import {FilterDataChanged, FilterDataChangedEvent, TimelineFilterService} from '../services/timeline-filter.service';
import {TimelineGoogleAnalitcsService} from '../services/timeline-google-analitcs.service';
import {StateManagementService} from '../services/state-management.service';
import {log} from 'util';
import {environment} from '../../environments/environment';
import {QuestionnaireService} from '../services/questionnaire.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css'],
  animations: [
    trigger('eventAnimator', [
      transition('* => wobble', animate(1000, keyframes(kf.wobble))),
      transition('* => swing', animate(1000, keyframes(kf.swing))),
      transition('* => jello', animate(1000, keyframes(kf.jello))),
      transition('* => zoomOutRight', animate(1000, keyframes(kf.zoomOutRight))),
      transition('* => slideOutLeft', animate(1000, keyframes(kf.slideOutLeft))),
      transition('* => rotateOutUpRight', animate(1000, keyframes(kf.rotateOutUpRight))),
      transition('* => flipOutY', animate(1000, keyframes(kf.flipOutY))),
      transition('* => lightSpeedIn', animate(1000, keyframes(kf.lightSpeedIn))),
      transition('void => *', animate(100, keyframes(kf.fadeIn)))
    ])
  ]
  // ,
  // host: {
  //   '(window:resize)': 'onResize($event)'
  // },
  //  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements OnInit, OnDestroy {

  @ViewChild('myaccordion') myPanels: MatAccordion;
  @ViewChild('myExpansionPanel') myExpansionPanel: MatExpansionPanel;

  isOpen = true;
  showPopover = [false, false, false, false, false, false, false];
  faktorGustine: number = 10;
  displayPage = 'MainPage'; /// 1.MainPage   2.About
  title = 'ebart-proba';
  numOfLines: number[] = [1, 2, 3, 4];
  test = [];
  dogadjaji = [
    {title: 'Prvi naslov', category: 'Sport', datum: '02-03-2015'},
    {title: 'Drugi naslov', category: 'Sport', datum: '25-07-2016'},
    {title: 'Treci naslov', category: 'Info', datum: '08-07-2017'},
    {title: 'Cetvrti naslov', category: 'Sport', datum: '14-07-2018'}
  ];
  timelineEvents: TimelineEvent[];
  allTimelineEvents: TimelineEvent[];
  hiddentTimelineEvents: TimelineEvent[];
  filteredTimelineEvents: TimelineEvent[];
  zanimljivostiTimeLineEvents: TimelineEvent[];
  allTimelineeventsTemp: TimelineEvent[];
  fiteredTimelineeventsTemp: TimelineEvent[];
  significanceLevels: SignificanseEventInfo[];
  selectedSignificanceLevel = 0;
  showEventInfoMobile = false;
  mobileViewMode: MobileViewMode;
  _mobileViewMode = MobileViewMode;
  activeMediaSourceNum: number;


  listOfYearEvents: YearEvent[];
  showYearATabs = false;
  selectedOnDayEvent: OnDayEvent;
  showSourcesInformation = false;
  moveValue: number;
  movingWithAnimations: boolean;
  zooming = false;

  numOfLanes = 3;
  laneHeight = 70;

  minDate = new Date(1991, 0, 1);
  maxDate = new Date(2015, 0, 1);
  exampleHeader = ExampleHeaderComponent;

  /*  Only for testing purposes  */
  showSourcesInformationTest = false;
  labelPosition = 'after';
  /*  Only for testing purposes  - END */

  categories: EventCategory[];
  pinchEventData: string;
  panStartedX: number;

  filteredStartDate: Date;
  filteredEndDate: Date;
  filterEventName: string;
  oldFilterEventName: string;

  allMediaTags: MediaTag[] = new Array();

  showMajorLines = true;
  showMinorLines = true;
  landingPage = true;


  pdfPath = '../src/assets/Prilog3ID4013.pdf';

  @ViewChild('filterByTagTrigger', {read: MatAutocompleteTrigger}) trigger: MatAutocompleteTrigger;
  @ViewChild('filterByRubrikaTrigger', {read: MatAutocompleteTrigger}) filterRubrikaTrigger: MatAutocompleteTrigger;
  startDate = moment('01-11-1990', 'DD-MM-YYYY').toDate();
  endDate = moment('01-05-1991', 'DD-MM-YYYY').toDate();
  // SBB
  // staticka IP adresa je 600 din mesecno.
  // 011 - 400 2222  Danijela
  currentZoom = 3;
  dayLength = 0;
  periodLength = 0;
  leftScroll = -30;
  timelineSize = 0;
  timelineScrollAnimation: string;
  minorLines: LineInfo[];
  majorLines: LineInfo[];
  /*  Moze da se stavi i samo selectedEvent ali je ovako jednostavnije u html-u  */
  selectedEvent: TimelineEvent;
  selectedEventID: string;
  selectedEventIndex: number;

  chosenCategories: EventCategory[];
  chosenTags: MediaTag[];
  categoriesCtrl = new FormControl();
  mediaTagCtrl = new FormControl();
  filteredCategories: Observable<EventCategory[]>;
  filteredMediaTags: Observable<MediaTag[]>;
  filteredMediaTagGroups: Observable<MediaTagGroup[]>;

  mediaTagGroups: Array<MediaTagGroup>;
  monthNames = ['Jan', 'Feb', 'Mart', 'Apr', 'Maj', 'Jun',
    'Jul', 'Avg', 'Sept', 'Okt', 'Nov', 'Dec'];

  badgeLeft = 0;
  badgeRight = 0;
  readonly one_day = 1000 * 60 * 60 * 24;
  halfSizeDays: number;

  significanceLevelEventCount: number[];
  zoomLevel = 2;
  onDayEvents: OnDayEvent[] = [];
  dataLoading = false;

  constructor(public dialog: MatDialog, private iconRegistry: MatIconRegistry, private httpTimelineservice: TimelineEventService,
              private sanitizer: DomSanitizer, private timelineeventUtilService: TimelineUtilService,
              private router: Router, private activeRoute: ActivatedRoute, public deviceDetector: DeviceDetectorService,
              private timelineFilterService: TimelineFilterService,
              private googleAnalyticsService: TimelineGoogleAnalitcsService,
              private stateManagementService: StateManagementService,
              private changeDetectorRef: ChangeDetectorRef,
              private questionnaireService: QuestionnaireService
  ) {
    iconRegistry.addSvgIcon(
      'my-remove-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icon-delete-small-hover.png'));
  }

  ngOnDestroy(): void {
    // this.mobileQuery.removeListener(this._mobileQueryListener);
    this.preserveState();
  }

  ngOnInit() {
    // setTimeout( ()  => this.openQuestionaryDialog(), environment.showQuestionnaireAfter * 60 * 1000);
    const oldState = this.stateManagementService.getState('TIMELINE_STATE');

    this.dataLoading = true;
    this.initCategories();
    console.log('NG ON INIT');
    this.resetData();
    this.httpTimelineservice.setEndpointPathByUrl(window.location.href);
    this.readState();
    if (this.timelineEvents && this.timelineEvents.length) {

      console.log('Data already loaded!');
      this.activeRoute.paramMap.subscribe(
        params => {
          const evtID = params.get('eventId');
          this.prepareDataForView(evtID);
        }
      );
    } else {
      console.log('Data already loaded!');
      const allEventsParamCombine = combineLatest(
        this.activeRoute.paramMap,
        this.httpTimelineservice.getAllTimelineEvents()
      ).subscribe(
        ([params, data]) => {
          const evtID = params.get('eventId');
          this.prepareDataForView(evtID, data);
        }
      );
    }


    this.subscribeToFilterChanges();
  }

  resetAllFilters() {
    this.categories.forEach(categ => categ.selected = false);
  }

  resetSignificanceLevel() {
    this.significanceLevels = [];
    for (let i = 0; i <= 10; i++) {
      this.significanceLevels.push(new SignificanseEventInfo(i, 0));
    }
  }

  makeAllTimeLineEvents(data: any) {
    this.zanimljivostiTimeLineEvents = new Array();
    Object.keys(data).forEach(key => {
      const obj: any = data[key];
      const event = this.createTimeLineEvent(key, obj['ColumnValues']);
      if (event.date.getFullYear() < 1980 || event.date.getFullYear() > 2100) {
        console.log('LOS DOGADJAJJJJJJJJJJ:', event);
      } else {
        this.significanceLevelEventCount[event.significance]++;
        if (event.category && event.category.name === 'Zanimljivosti') {
          this.zanimljivostiTimeLineEvents.push(event);
        } else {
          this.allTimelineEvents.push(event);
        }
      }


      // Object.keys(obj).forEach(k => console.log('key:' + k + ', values:' + obj[k]));
    });
    this.zanimljivostiTimeLineEvents.sort(this.compareDates);
    this.allTimelineEvents.sort(this.compareDates);
    this.filteredTimelineEvents = new Array().concat(this.allTimelineEvents);
    console.log('dif cats:', this.diffCats);
  }


  initViewForSelectedEvent(selectedEventID: string) {
    if (selectedEventID === '0' || !this.allTimelineEvents) {
      const popoverTimer = timer(3000, 4000);
      let i = 0;
      console.log('this.timelineeventUtilService.popoversDisplayed:', this.showPopover);
      console.log('this.timelineeventUtilService.popoversDisplayed:', this.timelineeventUtilService.popoversDisplayed);
      if (!this.timelineeventUtilService.popoversDisplayed) {
        const timerSubscrition = popoverTimer.subscribe(
          val => {
            if (i > 0) {
              this.showPopover[i - 1] = false;
              this.timelineeventUtilService.popoversDisplayed = true;
            }
            if (i < this.showPopover.length) {
              this.showPopover[i] = true;
              i++;
            } else {
              timerSubscrition.unsubscribe();
            }
          }
        );
      }

      //return;
    }
    if (!this.filteredTimelineEvents || this.filteredTimelineEvents.length === 0) {
      return;
    }
    console.log('SELECTED EVENT:' + selectedEventID);
    if (selectedEventID === '0') {
      this.filteredTimelineEvents = [...this.allTimelineEvents];
      this.filteredTimelineEvents.sort(this.compareDates);
      const highestPriority = this.allTimelineEvents.filter(evt => evt.significance === 0);
      selectedEventID = highestPriority[Math.floor(highestPriority.length / 2)].id;

      this.selectedEventID = this.selectedEventID;
    } else {
      this.landingPage = false;
    }
    if (selectedEventID === '-1' || selectedEventID === '-5') {
      //this.filteredTimelineEvents = [...this.timelineEvents];
      /* Prvo utvrdjujemo stepn prioriteta koji prikazujemo */
      let sigLevel = 0;
      let uk = 0;
      while (sigLevel <= 10 && uk < 30) {
        uk += this.significanceLevels[sigLevel].noOfEvents;
        sigLevel++;
      }
      const sigList = this.filteredTimelineEvents.filter(evt => evt.significance <= sigLevel);
      selectedEventID = sigList[Math.floor(sigList.length / 2)].id;
      this.selectedEventID = selectedEventID;
    }
    const selectedEvent = this.filteredTimelineEvents.find(event => event.id === selectedEventID);
    console.log('sel event', selectedEvent, selectedEventID);
    this.selectedEvent = selectedEvent;
    //this.zoomLevel = selectedEvent.significance;
    if (selectedEvent) {

      this.selectedSignificanceLevel = selectedEvent.significance;
      console.log('Sel event:' + selectedEvent.title + '; SIGNIF: ' + selectedEvent.significance);
      //this.filteredTimelineEvents = [];
      const eventList = [];
      this.hiddentTimelineEvents = [];
      this.filteredTimelineEvents.forEach(evt => {
          if (evt.significance <= selectedEvent.significance) {
            //this.filteredTimelineEvents.push(evt);
            eventList.push(evt);
          } else {
            this.hiddentTimelineEvents.push(evt);
          }
        }
      );

      this.fillSignificansList(this.filteredTimelineEvents);
      this.fillYearEventsForTabs();

      //this.timelineEvents = [...this.filteredTimelineEvents];
      this.timelineEvents = [...eventList];
      const index = this.timelineEvents.findIndex(evt => evt.id === this.selectedEventID);
      console.log('INdex of event:' + index);
      let daysToShow = 0;
      if (selectedEvent && index >= 0) {
        //this.timelineEventSelected(selectedEvent, index);

        if (this.timelineEvents.length < 30) {
          if (this.timelineEvents.length === 1) {
            daysToShow = this.inBetween(this.timelineEvents[0].date, moment(this.timelineEvents[0].date).add(2, 'M').toDate());
          } else {
            daysToShow = this.inBetween(this.timelineEvents[0].date, this.timelineEvents[this.timelineEvents.length - 1].date);
          }
        } else {
          if (index >= 15) {
            daysToShow = this.inBetween(this.timelineEvents[index - 15].date, this.timelineEvents[index].date) * 2;
          } else {
            // let lastToSee = 0;
            if (index < 15) {   //&& this.timelineEvents.length - index > 15
              const lastToSee = this.timelineEvents.length - index > 14 ? index + 15 : this.timelineEvents.length - 1;
              console.log('days to show- first index:' + index + ' - last to see: ' + lastToSee);
              daysToShow = this.inBetween(this.timelineEvents[index].date, this.timelineEvents[lastToSee].date) * 2;
            }
          }
        }

        console.log('days to show:' + daysToShow);
        if (this.timelineEvents.length > 0) {
          this.dayLength = this.timelineSize / daysToShow;
        }
      }

        if (this.landingPage && this.selectedOnDayEvent) {
          console.log('selecting timeline event!!!');
          this.selectedEvent = this.selectedOnDayEvent.timelineEvent;
          this.selectedEventID = this.selectedOnDayEvent.timelineEvent.id;
          this.timelineEventSelected(this.selectedOnDayEvent.timelineEvent, -1);
        } else {
          this.timelineEventSelected(selectedEvent, index);
        }

    }
    this.defineMinorLines();
    this.defineMajorLines();
  }

  fillSignificansList(timelineEvents: TimelineEvent[]) {
    let uk = 0;
    this.resetSignificanceLevel();
    timelineEvents.forEach(
      tmevt => {
        //console.log('SIG of event:' + tmevt.significance);
        if (this.significanceLevels && this.significanceLevels[tmevt.significance]) {
          this.significanceLevels[tmevt.significance].noOfEvents++;
        }
      }
    );

    this.significanceLevels.forEach(
      sigLvl => {
        uk += sigLvl.noOfEvents;
        sigLvl.noOfEvents = uk;
      }
    );
  }


  fillYearEventsForTabs() {
    const listOfYearEventsTemp = new Array();
    const firstYear = this.filteredTimelineEvents[0].date.getFullYear();
    let currentYear = new YearEvent(firstYear);
    listOfYearEventsTemp.push(currentYear);
    this.filteredTimelineEvents.forEach(event => {
      if (currentYear.year < event.date.getFullYear()) {
        currentYear = new YearEvent(event.date.getFullYear());
        listOfYearEventsTemp.push(currentYear);
      }
      currentYear.listOfYearEvents.push(event);
    });

    this.listOfYearEvents = new Array();
    this.listOfYearEvents.push(...listOfYearEventsTemp);
    this.showYearATabs = true;
  }

  showEventsByRelevanceAndDensity() {

    if (this.filteredTimelineEvents.length === 0) {
      this.timelineEvents.length = 0;
      return;
    }
    /* Prvo da odredimo ponovo significant count  */
    this.significanceLevelEventCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // ima 10 nivoa znacajnosti
    this.filteredTimelineEvents.forEach(event => this.significanceLevelEventCount[event.significance]++);
    // console.log('showEventsByRelevanceAndDensity: Filtered events:' + this.filteredTimelineEvents.length);
    this.filteredTimelineEvents.sort(this.compareDates);

    /*  Pripremanje podataka za prikaz u tabovima.   */
    const listOfYearEventsTemp = new Array();
    const firstYear = this.filteredTimelineEvents[0].date.getFullYear();
    let currentYear = new YearEvent(firstYear);
    listOfYearEventsTemp.push(currentYear);
    this.filteredTimelineEvents.forEach(event => {
      if (currentYear.year < event.date.getFullYear()) {
        currentYear = new YearEvent(event.date.getFullYear());
        listOfYearEventsTemp.push(currentYear);
      }
      currentYear.listOfYearEvents.push(event);
    });

    this.listOfYearEvents = new Array();
    this.listOfYearEvents.push(...listOfYearEventsTemp);
    this.showYearATabs = true;
    /*  Pripremanje podataka za prikaz u tabovima.  - KRAJ */

    let ukupno = 0;
    // Odredjujemo significance koji treba biti prikazan
    let sig = 1;
    this.hiddentTimelineEvents = new Array();
    this.timelineEvents = new Array();
    if (this.landingPage) {
      sig = 0;
    } else {
      if (this.selectedEvent) {
        sig = this.selectedEvent.significance;
      } else {
        for (sig = 1; sig <= 10; sig++) {
          if (ukupno + this.significanceLevelEventCount[sig] > 30 * this.zoomLevel) {
            sig = sig > 1 ? sig - 1 : sig;
            break;
          } else {
            ukupno += this.significanceLevelEventCount[sig];
          }
        }
      }
    }

    // console.log('showEventsByRelevanceAndDensity: 1');
    const eventList: TimelineEvent [] = new Array();
    let numOfMVP = 0;
    this.filteredTimelineEvents.forEach(event => {
      event.daysFromBeginning = this.inBetween(this.startDate, event.date);
      if (event.significance === 0) {
        numOfMVP++;
      }
      if (event.significance <= sig) {
        eventList.push(event);
      } else {
        this.hiddentTimelineEvents.push(event);
      }
    });
    console.log('Broj SIG 0 eventova ' + numOfMVP);

    // console.log('showEventsByRelevanceAndDensity: 2 - RASPOREDJNEO NA HIDDEN I OVE KOJI SU VIDLJIVI.');
    /*  Ovo mozda i ne treba ali za svaki slucaj, proveriti pa izbaciti ako ne treba  */
    eventList.sort(this.compareDates);
    const firstEventDate = eventList[0].date;
    // TODO: ODREDITI KOJI JE DATUM POSLEDNJI, ZBOG PROBELMA SA NEKIM TEST PODACIMA
    for (let i = eventList.length - 1; i > 0; i--) {
      console.log('CISCENJE: ' + this.inBetween(eventList[i - 1], eventList[i]));
      if (eventList[i].date.getFullYear() < 2015) {
        if (i < eventList.length - 1) {
          eventList.splice(i + 1);
        }
        break;
      }
    }
    const lastEventDate = eventList[eventList.length - 1].date;
    let filteredDayLength = 10;
    // DOGOVOR JE DA SE NA TIMELINU PRIKAZE INICIJALNO 60 DOGADJAJA. Ako postoji vise od tog broja dogadjaja mi moramo da
    // izracunamo drugaciji opseg koji ce biti prikazan i recimo postaviti sredinu timelina!
    this.faktorGustine = eventList.length / 60;
    if (eventList.length > 1) {
      filteredDayLength = ((this.timelineSize - 100) / this.inBetween(firstEventDate, lastEventDate));
    }
    const daysFromStart = this.inBetween(this.startDate, firstEventDate);
    this.dayLength = filteredDayLength;
    this.leftScroll = daysFromStart * filteredDayLength * (-1) - this.timelineSize * (this.faktorGustine / 2);
    /* Ako je jedini događaj nije loše staviti ga na sredinu   */
    if (eventList.length === 1) {
      //this.leftScroll -= (this.timelineSize / 2);
    }
    // console.log('showEventsByRelevanceAndDensity: 3 - ODREDJENA VELICINA I SCROLL.: left scroll:'
    // + this.leftScroll + ', dayLength:' + this.dayLength

    //
    // let ind = 0;
    // const dueTime = 2000 / eventList.length;
    // console.log('due time:' + dueTime);
    // const t = timer(dueTime, dueTime);

    this.defineMajorLines();
    this.defineMinorLines();
    eventList.forEach(evt => this.timelineEvents.push(evt));
    this.countUnvisibleEvents();


    // const timerSubscription = t.subscribe(time => {
    //   this.timelineEvents.push(eventList[ind++]);
    //   // console.log('showEventsByRelevanceAndDensity: 4 - DODAT NOVI ELEMENT. :' + ind + '==' + eventList.length);
    //   if (ind === eventList.length) {
    //     // console.log('showEventsByRelevanceAndDensity: 5 - URADJEN UNSUBSCRIBE.');
    //     if (eventList.length === 1) {
    //       this.timelineEventSelected(eventList[0], 0);
    //     }
    //     timerSubscription.unsubscribe();
    //     this.countUnvisibleEvents();
    // });

    let ind = -1;
    if (this.selectedEvent) {
      ind = this.timelineEvents.findIndex(evt => evt.id === this.selectedEvent.id);
    }
    // Ako slucajno ne nadje u listi da stavi srednji.
    if (ind === -1) {
      ind = Math.floor(this.timelineEvents.length / 2);
    }
    this.timelineEventSelected(this.timelineEvents[ind], ind);
    //this.initViewForSelectedEvent(this.timelineEvents[ind].id);
  }

  initData() {

    if (!this.allTimelineEvents || this.allTimelineEvents.length === 0) {
      this.timelineEvents = [];
      this.filteredTimelineEvents = [];
      return;
    }
    this.allTimelineEvents.sort(this.compareDates);
    this.startDate = this.allTimelineEvents[0].date;
    // TODO: OVDE TREBA NAPRAVITI ALGORITAM DA IZBACI ONE KOJI STRCE
    this.endDate = this.allTimelineEvents[this.allTimelineEvents.length - 1].date;
    this.filteredStartDate = this.startDate;
    this.filteredEndDate = this.endDate;
    this.timelineFilterService.externalFilterDataHasChanged(new FilterDataChanged(
      FilterDataChangedEvent.DATE_CHANGED,
      {
        filteredStartDate: this.filteredStartDate,
        filteredEndDate: this.filteredEndDate
      }));

    const interval = setInterval(
      () => {
        const elem = document.getElementById('timeLineContainer');
        if (!elem) {
          return;
        }
        console.log(' starting to do stuff', moment().format('h:mm:ss'));
        this.izracunajBrojDanaIVelicinu();
        this.calculateTimeLineEventsAttributes();
        this.defineMinorLines();
        this.defineMajorLines();

        //this.initAllMediaTags();
        this.chosenCategories = new Array();
        this.chosenTags = new Array();
        this.filteredCategories = this.categoriesCtrl.valueChanges
          .pipe(
            startWith(''),
            map(state => state ? this._filterCategories(state) : this.categories.slice())
          );
        this.filteredMediaTagGroups = this.mediaTagCtrl.valueChanges
          .pipe(
            startWith(''),
            map(mediaTag => mediaTag ? this.filterTagGroups(mediaTag) : this.mediaTagGroups.slice())
          );

        this.countUnvisibleEvents();
        /*  TODO: Ovo izmeniti kako bi se stvarno prikazali samo oni koji su sakriveni u ovom zoom-u  */
        // this.hiddentTimelineEvents = new Array().concat(this.timelineEvents);
        console.log('Clearing interval:', moment().format('h:mm:ss'));
        clearInterval(interval);
      }
      , 200
    );

  }

  displayFn(cat: EventCategory): string {

    // return cat ? cat.name : undefined;
    return '';
  }

  displayFnTags(tag: MediaTag): string {
    return '';
  }

  zanimljivostiCat = new EventCategory('Zanimljivosti', '#df6d27', false, false);

  initCategories() {
    this.categories = new Array();

    this.categories.push(new EventCategory('Politika', '#E32121', true));
    this.categories.push(new EventCategory('Ekonomija', '#DEC024', true));
    this.categories.push(new EventCategory('Društvo', '#1c25dd', true));
    this.categories.push(new EventCategory('Sport', '#28772a', true));
    // this.categories.push(new EventCategory('Statistika', '#6b2f75'));
    this.categories.push(this.zanimljivostiCat);
    this.categories.push(new EventCategory('Kultura', '#938b80', true));
  }


  defineMinorLines() {
    this.showMinorLines = false;
    this.minorLines = new Array();
    /*  ako je duzina dana premala onda ce ici bez minor lajnova  */
    if (this.dayLength < 0.15) {
      return;
    }
    let startMonth = this.startDate.getMonth();
    let startYear = this.startDate.getFullYear();
    let endMonth = this.endDate.getMonth();
    let endYear = this.endDate.getFullYear();
    // console.log('this.timelineEvents[0].date.getMonth();' + this.timelineEvents[0].date);
    // console.log('this.timelineEvents[lasts].date.getMonth();' + this.timelineEvents[this.timelineEvents.length - 1].date)
    if (this.timelineEvents && this.timelineEvents.length > 0) {

      startMonth = this.timelineEvents[0].date.getMonth();
      startYear = this.timelineEvents[0].date.getFullYear();
      endMonth = this.timelineEvents[this.timelineEvents.length - 1].date.getMonth();
      endYear = this.timelineEvents[this.timelineEvents.length - 1].date.getFullYear();
      console.log('DEFINING MINOR LINES: ' + startYear + ', end year ' + endYear);
    }
    let j: number = startMonth;
    let monthIncrement = 1;

    if (this.dayLength < 0.3) {
      monthIncrement = 6;
    } else {
      if (this.dayLength < 0.55) {
        monthIncrement = 3;
      } else {
        if (this.dayLength < 1) {
          monthIncrement = 2;
        }
      }
    }

    for (let i = startYear; i <= endYear; i++) {
      if (i !== startYear) {
        j = 1;
      }
      while ((i < endYear && j <= 12) || (j <= endMonth + 1 && i === endYear)) {

        const lineInfo = new LineInfo();
        lineInfo.lineDate = moment('01-' + j + '-' + i, 'DD-MM-YYYY').toDate();
        lineInfo.daysFromBegining = this.inBetween(this.startDate, lineInfo.lineDate);
        lineInfo.dateText = this.monthNames[j - 1];
        this.minorLines.push(lineInfo);

        if (monthIncrement === 1 && this.dayLength > 2.50) {
          let increment = 10;
          if (this.dayLength < 4) {
            increment = 15;
          }
          if (this.dayLength > 10) {
            increment = 5;
          }

          let loopNum = 0;
          for (let n = increment; n < 26; n += increment) {
            const dayLineInfo = new LineInfo();
            dayLineInfo.lineDate = moment(n + '-' + j + '-' + i, 'DD-MM-YYYY').toDate();
            // console.log('line date:' + lineInfo.lineDate);
            dayLineInfo.daysFromBegining = this.inBetween(this.startDate, dayLineInfo.lineDate);
            dayLineInfo.dateText = n + '';
            this.minorLines.push(dayLineInfo);
            // if (loopNum === 0) {
            //   /* smanjiti n da bi posle bile okrugle cifre.   */
            //   n--;
            // }
            loopNum++;
          }
        }

        j += monthIncrement;
      }
    }
    this.showMinorLines = true;

  }

  defineMajorLines() {
    this.showMajorLines = false;
    this.majorLines = new Array();
    let startYear = this.startDate.getFullYear();
    let endYear = this.endDate.getFullYear();
    if (this.timelineEvents && this.timelineEvents.length > 0) {
      startYear = this.timelineEvents[0].date.getFullYear();
      endYear = this.timelineEvents[this.timelineEvents.length - 1].date.getFullYear();
    }

    for (let i = startYear + 1; i <= endYear; i++) {
      const lineInfo = new LineInfo();
      lineInfo.lineDate = moment('01-01-' + i, 'DD-MM-YYYY').toDate();
      lineInfo.daysFromBegining = this.inBetween(this.startDate, lineInfo.lineDate);
      lineInfo.dateText = i + '';
      this.majorLines.push(lineInfo);
    }
    this.showMajorLines = true;
  }

  izracunajBrojDanaIVelicinu() {
    this.periodLength = this.inBetween(this.startDate, this.endDate);
    console.log('PERIOD: ' + this.periodLength);
    const elem = document.getElementById('timeLineContainer');
    if (!elem) {
      return;
    }
    this.timelineSize = this.deviceDetector.isMobile() ? elem.clientHeight : elem.clientWidth;
    this.dayLength = (elem.clientWidth - (this.deviceDetector.isMobile() ? 40 : 100)) / this.periodLength;
    console.log('this.timelineSize: ' + this.timelineSize);
    console.log('this.dayLength: ' + this.dayLength);
    /*  CHANGED 11.11.2019 */
    this.halfSizeDays = (this.timelineSize / 2) / this.dayLength;
    console.log('start date:', this.startDate.getTime());
    console.log('end date:', this.endDate.getTime());
    this.startDate = new Date(this.startDate.getTime() - (this.halfSizeDays * this.one_day));
    this.endDate = new Date(this.endDate.getTime() + (this.halfSizeDays * this.one_day));
    this.leftScroll = this.halfSizeDays * this.dayLength * -1;
  }

  makeTimeLineEvents() {
    this.timelineEvents = new Array();
    const diffCAts = [];
    this.dogadjaji.forEach((dog, ind) => {
      const timelineEvent = new TimelineEvent();

      timelineEvent.title = dog.title;
      timelineEvent.date = moment(dog.datum, 'DD-MM-YYYY').toDate();
      timelineEvent.daysFromBeginning = this.inBetween(timelineEvent.date, this.startDate);
      timelineEvent.isActive = false;
      timelineEvent.lane = ind % 3;
      timelineEvent.category = this.categories.find(cat => cat.name === dog.category);
      const i = diffCAts.findIndex(cat => cat === dog.category);
      if (i < 0) {
        diffCAts.push(dog.category);
      }
      if (!timelineEvent.category) {
        console.log('NEPOZNATA KATEGORIJA:', dog.category);
      }
      this.timelineEvents.push(timelineEvent);
    });
    console.log('cats:', diffCAts);
  }


  //categorieSelected(event: MatAutocompleteSelectedEvent) {
  categorieSelected(event: any) {
    console.log('Selektovana je neka kategorija:');
    /*  TODO: Osmisliti bolji algoritam za ovo.   */
    if (this.zanimljivostiCat.selected) {
      this.allTimelineEvents = [...this.allTimelineeventsTemp];
      this.zanimljivostiCat.selected = false;
    }
    this.chosenCategories = [...this.categories.filter(cat => cat.selected)];
    this.filterAllTimelineEvents();
  }

  tagSelected(event: MatAutocompleteSelectedEvent) {
    console.log('selected value:' + event.option.value);
    this.chosenTags.push(event.option.value);
    const ind = this.allMediaTags.findIndex(cat => cat === event.option.value);
    console.log('ind:' + ind);
    if (ind >= 0) {
      console.log('TAG:' + JSON.stringify(this.allMediaTags[ind]));
      this.allMediaTags.splice(ind, 1);
    }
    this.filterAllTimelineEvents();
    this.selectedEvent = null;
    this.selectedEventIndex = -1;
    this.landingPage = false;
  }

  showTeaserTimeLine($event: MediaTag[]) {
    this.chosenTags = [...$event];
    this.filterAllTimelineEvents();
    this.selectedEvent = null;
    this.selectedEventIndex = -1;
  }

  moveLeft() {
    // console.log('moving left.');
    const movableAreaWidth = document.getElementById('movableTimeline').clientWidth;
    // console.log('movable area:' + movableAreaWidth);
    //this.leftScroll = this.leftScroll + movableAreaWidth < this.timelineSize - 30 ? this.leftScroll : this.leftScroll - 30;
    this.leftScroll += 100;

    this.moveValue = +100;
    //this.movingWithAnimations = true;
    this.timelineScrollAnimation = 'lightSpeedIn';
    const t = timer(2000);
    t.subscribe(val => {
      this.timelineScrollAnimation = '';
      this.movingWithAnimations = false;
    });
    setTimeout(this.countUnvisibleEvents(), 0);
  }

  moveRight() {
    console.log('moving right:' + this.leftScroll);
    //this.leftScroll = this.leftScroll < -30 ? this.leftScroll + 30 : this.leftScroll;
    this.leftScroll -= 100;
    //this.movingWithAnimations = true;
    this.timelineScrollAnimation = 'lightSpeedIn';
    // const t = timer(2000);
    // t.subscribe(val => {
    //   this.timelineScrollAnimation = '';
    //   this.movingWithAnimations = false;
    // });
    setTimeout(this.countUnvisibleEvents(), 0);
  }

  dragStart(event: DragEvent) {
    console.log('DRAG STARTED:' + event.clientX + ' , ' + JSON.stringify(event));
  }

  dragHappened(event: any) {
    console.log('Drag happened:');
  }

  mouseEvent(event: any, eventType: string) {
    console.log('mouse event' + eventType + ' happened:' + JSON.stringify(event));
  }

  srediDatume() {
    console.log('Sredjujem datume');

  }


  zoomIn() {
    // if (this.zoomLevel === 10 || this.zooming) {
    //   return;
    // }
    this.zooming = true;
    //let firstVisibleDay = moment(this.startDate).add(Math.abs(this.leftScroll) / this.dayLength);
    this.dayLength = this.dayLength * 1.50;
    if (this.selectedEvent) {
      this.leftScroll = this.leftScroll + ((this.timelineSize / 2) -
        (this.selectedEvent.daysFromBeginning * this.dayLength + this.leftScroll));
    } else {
      this.leftScroll = this.leftScroll - (Math.abs(this.leftScroll) + this.timelineSize / 2) * 0.50;
      //this.leftScroll = this.inBetween(firstVisibleDay, this.startDate) * this.dayLength * (-1);
    }

    // const t = timer(100);
    // t.subscribe(() => this.zooming = false);
    this.zoomLevel++;
    this.defineMinorLines();
    //this.addEventsAfterZoomIn();
    this.countUnvisibleEvents();
  }

  addEventsAfterZoomIn() {
    let ind = 0;
    const tempList: TimelineEvent[] = new Array();
    // TODO: moze se sortirati po datumu i signifivantnosti pa bi onda algoritam bio mozda malo bolji
    for (ind = 0; ind < this.hiddentTimelineEvents.length;) {
      if (this.hiddentTimelineEvents[ind].significance <= this.zoomLevel) {
        const event = this.hiddentTimelineEvents.splice(ind, 1)[0];
        tempList.push(event);
      } else {
        ind++;
      }
    }
    ind = 0;
    // const timerZoomIn = timer(10, 10);
    // const timerZoomInSubscription = timerZoomIn.subscribe(() => {
    //   if (ind >= tempList.length) {
    //     this.zooming = false;
    //     this.countUnvisibleEvents();
    //     timerZoomInSubscription.unsubscribe();
    //   } else {
    //     this.timelineEvents.push(tempList[ind++]);
    //   }
    // });

    tempList.forEach(event => this.timelineEvents.push(event));
    this.countUnvisibleEvents();
    this.fillYearEventsForTabs();
    this.zooming = false;

  }


  zoomOut() {
    // if (this.zoomLevel === 0 || this.zooming) {
    //   return;
    // }

    // ako su svi dogadjaji vec vidljivi nece dozvoliti dalje zoomiranje
    if (this.badgeLeft === 0 && this.badgeRight === 0) return;

    this.zooming = true;
    this.zoomLevel--;
    this.dayLength = this.dayLength / 1.5;
    this.leftScroll = this.leftScroll +
      ((Math.abs(this.leftScroll) + this.timelineSize / 2) - ((Math.abs(this.leftScroll) + this.timelineSize / 2) / 1.50));
    // const t = timer(100);
    // t.subscribe(() => this.zooming = false);
    this.defineMinorLines();
    //this.removeEventsAfterZoomOut();
    this.countUnvisibleEvents();
  }

  removeEventsAfterZoomOut() {
    let ind = 0;
    const tempList: TimelineEvent[] = new Array();
    // TODO: moze se sortirati po datumu i signifivantnosti pa bi onda algoritam bio mozda malo bolji
    for (ind = 0; ind < this.timelineEvents.length;) {
      if (this.timelineEvents[ind].significance > this.zoomLevel) {
        if (this.selectedEventIndex === ind) {
          this.selectedEvent = null;
          this.selectedEventIndex = -1;
        }
        const event = this.timelineEvents.splice(ind, 1)[0];
        if (this.selectedEventIndex > ind) {
          this.selectedEventIndex--;
        }
        ;
        tempList.push(event);
      } else {
        ind++;
      }
    }
    ind = 0;

    tempList.forEach(event => this.hiddentTimelineEvents.push(event));
    this.zooming = false;
    this.countUnvisibleEvents();

    // const timerZoomIn = timer(10, 10);
    // const timerZoomInSubscription = timerZoomIn.subscribe(() => {
    //   if (ind >= tempList.length) {
    //     this.zooming = false;
    //     this.countUnvisibleEvents();
    //     timerZoomInSubscription.unsubscribe();
    //   } else {
    //     this.hiddentTimelineEvents.push(tempList[ind++]);
    //   }
    // });

  }

  significanceLevelChanged(significanceLevel: number) {
    console.log('SIG CHANGE:' + this.selectedSignificanceLevel);
    this.landingPage = false;
    if (significanceLevel !== -1) {
      this.selectedSignificanceLevel = significanceLevel;
    }
    console.log('SIG CHANGE: 1');
    const allEventsTemp = [...this.timelineEvents, ...this.hiddentTimelineEvents];
    this.timelineEvents = [];
    this.hiddentTimelineEvents = [];
    console.log('SIG CHANGE: 2');
    // TODO: Use better sorting algorithm. Mix from both lists.
    allEventsTemp.sort(this.compareDates);
    const numOfDayFromStart = ((-1) * this.leftScroll + this.timelineSize / 2) / this.dayLength;
    console.log('Num of days from start:', numOfDayFromStart, this.startDate);
    const middleDate = moment(this.startDate).add(numOfDayFromStart, 'days').toDate();
    let closestEvent = null;
    let closestEventInd = -1;
    allEventsTemp.forEach(
      (evt, ind, arr) => {
        if (evt.significance <= this.selectedSignificanceLevel) {
          //console.log('SIG LEVEL - DATUM: ', evt.date, middleDate);
          if (evt.date > middleDate && !closestEvent) {
            closestEvent = evt;
            closestEventInd = this.timelineEvents.length;
          }
          this.timelineEvents.push(evt);
        } else {
          this.hiddentTimelineEvents.push(evt);
        }
      }
    );
    /*  Ako nije u sitom opsegu nece naci closest event  */
    if (!closestEvent) {
      closestEventInd = Math.floor(this.timelineEvents.length / 2);
      closestEvent = this.timelineEvents[closestEvent];
    }
    console.log('days to show - Closest event ind:' + closestEventInd, closestEvent);
    let daysToShow = 0;
    if (this.timelineEvents && this.timelineEvents.length <= 30) {
      if (this.timelineEvents.length === 1) {
        daysToShow = this.inBetween(this.timelineEvents[0].date, moment(this.timelineEvents[0].date).add(2, 'M').toDate());
      } else {
        daysToShow = this.inBetween(this.timelineEvents[0].date, this.timelineEvents[this.timelineEvents.length - 1].date) * 2;
      }


    } else {

      if (closestEventInd >= 15) {
        daysToShow = this.inBetween(this.timelineEvents[closestEventInd - 15].date, this.timelineEvents[closestEventInd].date) * 2;
      } else {
        const lastToSee = this.timelineEvents.length - closestEventInd > 14 ? closestEventInd + 15 : this.timelineEvents.length - 1;
        console.log('days to show - last to see: ', lastToSee);
        daysToShow = this.inBetween(this.timelineEvents[closestEventInd].date, this.timelineEvents[lastToSee].date) * 2;
      }
    }

    console.log('days to show: ', daysToShow);

    this.dayLength = this.timelineSize / daysToShow;
    //this.leftScroll = this.dayLength * this.inBetween()
    this.timelineEventSelected(this.timelineEvents[closestEventInd], closestEventInd);
    console.log('SIG CHANGE: 3');
    //this.initViewForSelectedEvent('-1');
    //this.calculateDayLengthToFitSignificanceLevel();
    this.defineMinorLines();
    this.defineMajorLines();

    console.log('SIG CHANGE: 4');
  }

  moveContenet(event: any) {

    const moveIncrement = this.deviceDetector.isMobile() ? event.verticalIncrement : event.moveIncrement;
    // console.log('moving increment:', event);
    if (moveIncrement > 0) {
      this.leftScroll = this.leftScroll > moveIncrement ? this.leftScroll : this.leftScroll + moveIncrement;
    } else {
      if (!this.deviceDetector.isMobile()) {
        const movableAreaWidth = document.getElementById('movableTimeline').clientWidth;
        //console.log('movable area:' + movableAreaWidth);
        this.leftScroll = this.leftScroll + movableAreaWidth < this.timelineSize + moveIncrement ?
          this.leftScroll : this.leftScroll + moveIncrement;
      } else {
        this.leftScroll = this.leftScroll + moveIncrement;
      }

    }
    setTimeout(this.countUnvisibleEvents(), 10);

  }

  public inBetween(date1, date2) {
// Get 1 day in milliseconds
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
      console.log('NESTO NIJE DATUM:' + date1 + ', date2:' + date2);
      return 10;
    }
    const one_day = 1000 * 60 * 60 * 24;

// Convert both dates to milliseconds
    const date1_ms = date1.getTime();
    const date2_ms = date2.getTime();

// Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms;

// Convert back to days and return
    return Math.round(difference_ms / one_day);
  }

  private _filterCategories(value: any): EventCategory[] {
    console.log('value:' + value);
    if (value instanceof Object) {
      return new Array();
    }
    const filterValue = value.toLowerCase();
    return this.categories.filter(cat => cat.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterMediaTags(value: any): MediaTag[] {
    if (value instanceof Object) {
      return new Array();
    }
    const filterValue = value.toLowerCase();
    return this.allMediaTags.filter(tag => tag.tagName.toLowerCase().indexOf(filterValue) === 0);
  }

  timelineEventSelectedClick(timelineEvent: TimelineEvent, index: number) {
    this.landingPage = false;
    this.timelineEventSelected(timelineEvent, index);
    this.questionnaireService.increaseNoOfSelectedEvents();
    if (this.questionnaireService.shouldShowQuestionnaireAfterEventSelection()) {
      this.showQuestionnaireDialog();
    }
  }

  showQuestionnaireDialog(): void {
    const dialogRef = this.dialog.open(QuestionnaireModalComponent, {
      width: this.deviceDetector.isDesktop() ? '30%' : '95%',
      height: '85%'
    });
    dialogRef.afterClosed().subscribe((result) => {
      // Rekao je Ivan da upitnik otvaram samo jednom 08.02.2022.god.
       if (result && result.questionnaireSubmitted) {
         this.questionnaireService.markQuestionnaireAsSubmitted();
       } else {
         this.questionnaireService.markQuestionnaireAsCanceled();
       }
    });
  }


  timelineEventSelected(timelineEvent: TimelineEvent, index: number) {


    if (index >= 0 ) {
      this.leftScroll = this.leftScroll + ((this.timelineSize / 2) - (timelineEvent.daysFromBeginning * this.dayLength + this.leftScroll));
      // console.log('POSLE IZRACUNAVANJA: leftScroll=' + this.leftScroll);
      this.selectedEvent = timelineEvent;
      this.selectedEventID = timelineEvent.id;
      /*  Ovo je uvedeno zbog dugmica  */
      this.selectedEventIndex = index;
      timelineEvent.animation = '';
      this.showSourcesInformation = false;
      /*  ne prikazujem odmah grid sa izvorima da ne bih poremetio animaciju a i ako on ide odmah dalje da ne ucitava PDF bezveze  */
      const timerSourcesInfo = timer(1000);
      timerSourcesInfo.subscribe(val => this.showSourcesInformation = true);
      // const t = timer(300);
      // t.subscribe(val => {
      //   timelineEvent.animation = 'jello';
      //   console.log('timer:' + val);
      // });
      // this.movingWithAnimations = true;
      // this.timelineScrollAnimation = 'lightSpeedIn';
      // const timerAnimations = timer(200);
      // timerAnimations.subscribe(val => {
      //   this.timelineScrollAnimation = '';
      //   this.movingWithAnimations = false;
      // });
      setTimeout( () => this.googleAnalyticsService.eventEmitter('tajmlajn event selected', timelineEvent.category.name, 'tajmlajn_event_selected', timelineEvent.id, 10), 20);
    }
    // sakriti tabpve sa prikazom svih dogadjaja koji su prikazani na timeline-u
    //this.myPanels.closeAll();
    //this.myExpansionPanel.close();
    this.showYearATabs = false;
    console.log('ZATVARAM SVE');
    this.vratiPodatkeIzabranogDogadjaja(this.selectedEvent);
    setTimeout(this.countUnvisibleEvents(), 0);
    //this.landingPage = false; // TODO: check 25.09
  }

  diffCats = [];

  createTimeLineEvent(id: string, eventValuesJSON: any): TimelineEvent {
    const timeLineEvnt = new TimelineEvent();
    //console.log('KRENUO - KREIRAO EVENT');
    timeLineEvnt.mediaSources = new Array();
    timeLineEvnt.id = id;
    timeLineEvnt.title = eventValuesJSON['1'];
    timeLineEvnt.category = this.categories.find(cat => cat.name === eventValuesJSON['2']);
    if (!timeLineEvnt.category) {
      console.log('NEPOZNATA CAT:' + eventValuesJSON['2']);
    }


    if (!timeLineEvnt.category && this.diffCats.findIndex(cat => cat === eventValuesJSON['2']) < 0) {
      this.diffCats.push(eventValuesJSON['2']);
    }

    timeLineEvnt.significance = eventValuesJSON['8'];
    timeLineEvnt.date = moment(eventValuesJSON['0'], 'DD.MM.YYYY').toDate();

    timeLineEvnt.licnostiTags = this.getArrayForTags(eventValuesJSON['3']);
    timeLineEvnt.institucijaTags = this.getArrayForTags(eventValuesJSON['4']);
    timeLineEvnt.ostaloTags = this.getArrayForTags(eventValuesJSON['5']);
    timeLineEvnt.siriTags = this.getArrayForTags(eventValuesJSON['6']);

    //console.log('KREIRAO EVENT:' + timeLineEvnt.title + ':' + 'id:' + id + ',' + eventValuesJSON['3']);
    return timeLineEvnt;
  }

  getArrayForTags(eventValueJSON: any) {
    const newTagsArray = new Array();
    const tempArray: string[] = new Array();
    if (Object.keys(eventValueJSON).length > 0) {
      // console.log('NIZOVI TAGOVA ima ih vise:' + eventValueJSON);
      Object.keys(eventValueJSON).forEach(
        key => {
          // console.log('NIZOVI TAGOVA key:' + key + ':' + eventValueJSON[key]);
          tempArray.push(eventValueJSON[key]);
        });
    } else {
      // console.log('NIZOVI TAGOVA ima ih jedan:' + eventValueJSON);
      if (eventValueJSON && eventValueJSON.toString().trim().length > 0) {
        newTagsArray.push(eventValueJSON);
      }
    }

    if (tempArray.length > 0) {
      if (tempArray[0].trim().length === 1) {
        let tag = '';
        tempArray.forEach(elem => tag += elem);
        newTagsArray.push(tag);
      } else {
        tempArray.forEach(elem => newTagsArray.push(elem));
      }
    }
    return newTagsArray;
  }

  /*  Ovde se prima ono sto dodje kao Items. Tu imamo od interesa: datum, naziv, rubrika (prve tri su vec ucitane), opis,
        (tagovi) licnosti, institucija, ostalo(tagovi-kraj)
      izvori(niz ID izvora), mediji (niz odogavarajucih medija) */
  fillTimeLineEventDetails(timelineEvent: TimelineEvent, eventValuesJSON: any) {
    //console.log('opis:' + JSON.stringify(eventValuesJSON));
    timelineEvent.description = this.getSingleValue(eventValuesJSON['opis']);
    timelineEvent.title = this.getSingleValue(eventValuesJSON['naziv']);
    timelineEvent.smrt = this.getSingleValue(eventValuesJSON['smrt']) === 'DA' ? true : false;
    console.log('description:' + timelineEvent.description);
    const izvoriValues: string[] = this.getArrayOfValues(eventValuesJSON['izvori']);
    const medijiValues: string[] = this.getArrayOfValues(eventValuesJSON['mediji']);

    timelineEvent.licnostiTags = this.getArrayOfValues(eventValuesJSON['licnost']);
    timelineEvent.institucijaTags = this.getArrayOfValues(eventValuesJSON['institucija']);
    timelineEvent.ostaloTags = this.getArrayOfValues(eventValuesJSON['ostalo']);
    timelineEvent.siriTags = this.getArrayOfValues(eventValuesJSON['siri']);

    this.checkArrays(timelineEvent);

    console.log('izvori values:' + izvoriValues);
    // ovde bi trebalo proveriti da li je vec loadovana ako jeste da se ne radi ponovo.
    timelineEvent.mediaSources = new Array();
    izvoriValues.forEach((izvor, ind) => {
      const newMediaSource: MediaSource = new MediaSource();
      newMediaSource.id = izvor;
      newMediaSource.sourceInstitutionName = medijiValues[ind];
      timelineEvent.mediaSources.push(newMediaSource);
    });
    timelineEvent.detailDataLoaded = true;

  }

  getSingleValue(eventAttValuesJSON: any): any {
    if (!eventAttValuesJSON || !eventAttValuesJSON['Values'] || eventAttValuesJSON['Values'].length === 0) {
      return 'NEPOZNATO';
    }

    const obj = eventAttValuesJSON['Values'];
    return obj[0];
  }

  getArrayOfValues(eventAttValuesJSON: any): any[] {
    const valuesArray = new Array<any>();
    const obj = eventAttValuesJSON['Values'];
    //Object.keys(obj).forEach(key => valuesArray.push(obj[key]));
    return obj;
  }

  private calculateTimeLineEventsAttributes() {
    this.timelineEvents.forEach((timelineEvent, ind) => {
      timelineEvent.daysFromBeginning = this.inBetween(this.startDate, timelineEvent.date);
      timelineEvent.isActive = false;
      timelineEvent.lane = ind % 3;
    });

    /* TODO: Ovde mora da se osmisli mnogo bolji algoritam.  */
    this.allTimelineEvents.forEach((timelineEvent, ind) => {
      timelineEvent.daysFromBeginning = this.inBetween(this.startDate, timelineEvent.date);
      timelineEvent.isActive = false;
      timelineEvent.lane = ind % 3;
    });

  }

  vratiPodatkeIzabranogDogadjaja(selEvent: TimelineEvent) {
    this.httpTimelineservice.getTimelineEvent(selEvent.id).pipe(debounceTime(500)).subscribe(data => {
      Object.keys(data).forEach(key => {
        const obj: any = data[key];
        // console.log('dogadjaj detalji:' + JSON.stringify(obj['Items']));
        if (!selEvent.detailDataLoaded) {
          this.fillTimeLineEventDetails(selEvent, obj['Items']);
        }
        // ovo mora da se pozove ako nije ucitan neki mediasource...
        this.ucitajPodatkeZaSveIzvore(selEvent);
        // Object.keys(obj).forEach(k => console.log('key:' + k + ', values:' + obj[k]));
      });
    });
  }

  ucitajPodatkeZaSveIzvore(timelineEvent: TimelineEvent) {
    console.log('Ucitavam podatke za sve izvore');
    timelineEvent.mediaSources.forEach(mediaSource => {
      if (!mediaSource.dataloaded) {
        this.ucitajDetaljnePodatkeOIzvoru(mediaSource);
      }
      //}
    });
  }

  ucitajDetaljnePodatkeOIzvoru(mediaSource: MediaSource) {
    if (mediaSource.id == 'Nema izvora') {
      return;
    }
    this.httpTimelineservice.getMediaSourcedetails(mediaSource.id).subscribe(data => {
      Object.keys(data).forEach(key => {
        const obj: any = data[key];
        // console.log('dogadjaj detalji:' + JSON.stringify(obj['Items']));
        this.fillMediaSourceDetails(mediaSource, obj['Items']);
        // Object.keys(obj).forEach(k => console.log('key:' + k + ', values:' + obj[k]));
      });
    });
  }

  fillMediaSourceDetails(mediaSource: MediaSource, mediaSourceDetailsValues: any) {
    mediaSource.fileName = this.getSingleValue(mediaSourceDetailsValues['filename']);
    mediaSource.fullLink = this.getSingleValue(mediaSourceDetailsValues['link']);
    mediaSource.tip = this.getSingleValue(mediaSourceDetailsValues['tip']);
    mediaSource.thumbLink = this.getSingleValue(mediaSourceDetailsValues['link_tb']);
    mediaSource.thumbLink = mediaSource.thumbLink.replace(' ', '%20');
    mediaSource.autor = this.getSingleValue(mediaSourceDetailsValues['autor']);

    if (mediaSource.fullLink.toLowerCase().startsWith('Gre')) {
      mediaSource.wrongLink = true;
    }
    mediaSource.sourceInstitutionName = this.getSingleValue(mediaSourceDetailsValues['medij']);
    mediaSource.newLink = mediaSource.fullLink.replace(' ', '%20');
    mediaSource.sanitizedLink = mediaSource.fullLink.replace(' ', '%20');
    // console.log('full link:' + mediaSource.fullLink);
    // console.log('sanitized link:' + mediaSource.sanitizedLink);
    mediaSource.dataloaded = true;
    if (mediaSource.tip !== 'Audio') {
      mediaSource.sourceType = this.getSourceTypeFromFileName(mediaSource.fileName);
    } else {
      mediaSource.sourceType = 'AUDIO';
    }
    mediaSource.dataloaded = true;
  }

  getSourceTypeFromFileName(fileName: string): string {
    const ind = fileName.lastIndexOf('.');
    const fileExtension = fileName.substr(ind + 1);
    // console.log('file:' + fileName + '; file ext:' + fileExtension);
    switch (fileExtension.toLowerCase()) {

      case 'jpg':
      case 'png':
      case 'gif':
        return 'IMAGE';
      case 'mp4':
      case 'mov':
      case 'wmv':
      case 'avi':
        return 'VIDEO';
      case 'pdf':
        return 'PDF';
      case 'mp3':
        return 'AUDIO';
      default:
        'NEPOZNATO';

    }
  }

  vratiUrlTekst(mediaSource: MediaSource): string {
    return 'url(\"' + mediaSource.fullLink + '\")';
  }


  initAllMediaTags() {
    this.httpTimelineservice.getAllTags().subscribe(data => {
      this.allMediaTags = new Array();
      this.mediaTagGroups = new Array<MediaTagGroup>();
      Object.keys(data).forEach(key => {
        const obj: any = data[key];
        const newMediaTag = new MediaTag();
        newMediaTag.id = key;
        newMediaTag.tagType = obj['ColumnValues'][0];
        newMediaTag.tagName = obj['ColumnValues'][1];
        this.allMediaTags.push(newMediaTag);

        // if (newMediaTag.tagName ) console.log('media tag:' + newMediaTag.tagType + ', mediaTypeValue=' + newMediaTag.tagName);
        if (newMediaTag.tagType && newMediaTag.tagType.trim() !== '' && newMediaTag.tagName && newMediaTag.tagName.trim() !== '') {

          // console.log('media tag:' + newMediaTag.tagType + ', mediaTypeValue=' + newMediaTag.tagName);
          const ind = this.mediaTagGroups.findIndex(mediaTagGroup => {
            // console.log(mediaTagGroup.mediaTagTypeName + '===' + newMediaTag.tagType);
            return mediaTagGroup.mediaTagTypeName === newMediaTag.tagType;
          });
          // console.log('Grupa za media tag:' + newMediaTag.tagType + ', je na mestu:' + ind);
          if (ind >= 0) {
            this.mediaTagGroups[ind].groupTags.push(newMediaTag);
            // console.log('pronasao sam Grupu za TAg:' + JSON.stringify(newMediaTag));
          } else {
            const newMediaTagGroup = new MediaTagGroup();
            newMediaTagGroup.mediaTagTypeName = newMediaTag.tagType;
            newMediaTagGroup.groupTags = new Array();
            newMediaTagGroup.groupTags.push(newMediaTag);
            this.mediaTagGroups.push(newMediaTagGroup);
          }
        }
      });
    });

  }

  mediaSourceLoaded(mediaSource: MediaSource) {
    mediaSource.imageLoaded = true;
    console.log('Image loaded for ' + mediaSource.id);
  }


  filterTagGroups(value: string): MediaTagGroup[] {
    console.log('Filtriram grupa: ' + this.mediaTagGroups.length + ', vrednost:' + value);
    if (value) {
      return this.mediaTagGroups.map(tagGroup => {
        const newMedGroup = new MediaTagGroup();
        newMedGroup.mediaTagTypeName = tagGroup.mediaTagTypeName;
        newMedGroup.groupTags = this._filterTags(tagGroup, value);
        return newMedGroup;
      }).filter(medGrp => medGrp.groupTags.length > 0);

    }

    return this.mediaTagGroups.slice();
  }

  _filterTags(tagGroup: MediaTagGroup, value: string): MediaTag[] {

    if (value) {
      const val = String(value).toLowerCase();
      return tagGroup.groupTags.filter(mediaTag => mediaTag.tagName.toLowerCase().indexOf(val) >= 0 && this.chosenTags.findIndex(mt => mt.id === mediaTag.id) < 0);
    } else {
      return tagGroup.groupTags.slice();
    }
  }

  openInNewTab(mediaSource: MediaSource) {
    window.open(mediaSource.fullLink, '_blank');
  }


  filterByTagFocus() {
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  filterByRubrikaFocus() {
    this.filterRubrikaTrigger._onChange('');
    this.filterRubrikaTrigger.openPanel();
  }

  oldInnerWidth = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    console.log('window: resized' + event.target.innerWidth);
    if (this.oldInnerWidth === 0) {
      this.oldInnerWidth = event.target.innerWidth;
    } else {
      if ((event.target.innerWidth < this.oldInnerWidth * 0.8) || (event.target.innerWidth > this.oldInnerWidth * 1.2)) {
        this.initData();
      }
    }
  }

  pinchEvent(eventType: string, event: any) {
    this.pinchEventData = eventType + ' === ' + JSON.stringify(event);
    this.pinchEventData += 'deltaX:' + event.deltaX;
    console.log('PINCH EVENT');
  }

  scrollEvent(event: any) {
    event.stopPropagation();
    console.log('SCROLL EVENT');
  }


  openDialog(selectedEvent: TimelineEvent, mediaSourceNum: number) {
    this.activeMediaSourceNum = mediaSourceNum;
    this.mobileViewMode = MobileViewMode.SOURCE_DETAILS;
    if (!this.deviceDetector.isMobile()) {
      const dialogRef = this.dialog.open(DialogsComponent, {
        width: '90%',
        height: '90%',
        data: {
          timelineEvent: selectedEvent,
          selectedMediaSourceNum: mediaSourceNum
        }
      });

    }
  }

  // @HostListener('window:beforeunload', ['$event'])
  // async beforeunloadHandler(event) {
  //   console.log('Window before unload');
  //   event.preventDefault();
  //   event.returnValue = 'Are you sure';
  //   // await this.openQuestionaryDialog();
  //   return event.returnValue;
  // }

  public async openQuestionaryDialog() {
    const dialogRef = this.dialog.open(QuestionnaireModalComponent, {
      width: '30%',
      height: '70%',
      data: {
        timelineEvent: null,
        selectedMediaSourceNum: null
      }
    });
    dialogRef.afterClosed().subscribe(() => console.log('DIALOG IS CLOSED'));
  }


  countUnvisibleEvents() {
    this.badgeLeft = 0;
    this.badgeRight = 0;

    // console.log('first date:' + this.timelineEvents[0].date);
    // console.log('last date:' + this.timelineEvents[this.timelineEvents.length - 1].date);

    const numOfLeftInvisibleDays = this.leftScroll / this.dayLength;
    const dayInMiliseconds = (1000 * 60 * 60 * 24);
    const firstVisibleDay = new Date(this.startDate.getTime() - numOfLeftInvisibleDays * dayInMiliseconds);
    const lastVisibleDay = new Date(firstVisibleDay.getTime() + Math.abs(this.timelineSize / this.dayLength) * dayInMiliseconds);

    // console.log('timeline width:' + this.timelineSize + ', day length:' + this.dayLength + 'days shown:' + (this.timelineSize / this.dayLength));
    //
    // console.log('first visible day:' + firstVisibleDay);
    // console.log('last visible day:' + lastVisibleDay);
    this.timelineEvents.forEach(evt => {
      if (firstVisibleDay.getTime() > evt.date.getTime()) {
        this.badgeLeft++;
      }
      if (lastVisibleDay.getTime() < evt.date.getTime()) {
        this.badgeRight++;
      }
    });
  }

  calculateZoomLevel() {
    const firstEventDate = this.allTimelineEvents[0].date;
    const lastEventDate = this.allTimelineEvents[this.allTimelineEvents.length - 1].date;
    const numberOfDays = this.inBetween(firstEventDate, lastEventDate);
    this.dayLength = this.timelineSize / numberOfDays;
  }


  compareDates(firstEvent: TimelineEvent, secondEvent: TimelineEvent): number {
    const firstDate = firstEvent.date.getTime();
    const secondDate = secondEvent.date.getTime();
    if (firstDate === secondDate) {
      return 0;
    } else {
      if (firstDate > secondDate) {
        return 1;
      } else {
        return -1;
      }
    }
  }

  checkArrays(timelineEvent: TimelineEvent) {
    /*  Oni salju niz sa jednim praznim podatkom ako ne postoji nista, pa to mora da se sredi.  */
    timelineEvent.institucijaTags = this.vratiSredjenNiz(timelineEvent.institucijaTags);
    timelineEvent.licnostiTags = this.vratiSredjenNiz(timelineEvent.licnostiTags);
    timelineEvent.ostaloTags = this.vratiSredjenNiz(timelineEvent.ostaloTags);
    timelineEvent.siriTags = this.vratiSredjenNiz(timelineEvent.siriTags);
  }

  vratiSredjenNiz(tagsArray: string[]): string[] {

    const newArray = new Array();
    tagsArray.forEach(tag => {
      if (tag && tag.trim().length > 0) {
        newArray.push(tag);
      }
    });

    return newArray;

  }

  removeSelectedRubrkaFromFilter(index: number) {
    const rubrika = this.chosenCategories[index];
    this.categories.push(this.chosenCategories.splice(index, 1)[0]);
    this.filterAllTimelineEvents();
  }


  filterAllTimelineEventsbyName() {
    console.log(this.filterEventName + ' = ' + this.oldFilterEventName);
    if (this.filterEventName !== this.oldFilterEventName) {
      this.oldFilterEventName = this.filterEventName;
      this.filterAllTimelineEvents();
    }
  }

  filterAllTimelineEvents() {
    /*  TODO: Treba videti kako da se resi ZOOM level. Recimo kada uklonis sve filterre pojave se svi dogadjaji a on zadrzi stari zoom level
    a dayWidth se smanji da svi dogadjaji budu prikazani...
    */
    this.googleAnalyticsService.eventEmitter('tajmlajn_events', 'filter_tajmlajn', 'filter', '', 5);
    //this.selectedEvent = null;
    this.selectedEventID = '';
    this.selectedEvent = null;
    this.selectedEventIndex = -1;

    if (!this.deviceDetector.isDesktop() && this.timelineFilterService.filterAppliedMobile) {
      console.log('changerd filter:', this.timelineFilterService.lastAppliedFilter.changedData);
      this.setNewFilterData(this.timelineFilterService.lastAppliedFilter);
    }

    let filteredEvents: TimelineEvent[] = new Array();
    if (this.chosenCategories && this.chosenCategories.length > 0) {
      console.log('filtriram sve dogadjaje' + this.allTimelineEvents.length);
      filteredEvents = this.allTimelineEvents.filter(
        timelineEvent =>
          this.chosenCategories.findIndex(rubrika => timelineEvent.category && rubrika.name === timelineEvent.category.name) >= 0
      );
    } else {
      console.log('postavljam sve dogadjaje' + this.allTimelineEvents.length);
      // this.timelineEvents.length = 0;
      filteredEvents = filteredEvents.concat(this.allTimelineEvents);
    }

    /*  Sada fitriramo po datumu!  */
    console.log('filtriram po datumu');
    filteredEvents = filteredEvents.filter(
      event =>
        event.date && (event.date instanceof Date)
        && (!this.filteredStartDate || event.date.getTime() >= this.filteredStartDate.getTime())
        && (!this.filteredEndDate || event.date.getTime() <= this.filteredEndDate.getTime()));
    console.log('Zavrsio filtriranje po datumu');
    /* Filter by name  */
    if (this.filterEventName && this.filterEventName.length > 0) {
      filteredEvents = filteredEvents.filter(timelineEvent => {
        return timelineEvent.title.toLowerCase().includes(this.filterEventName.toLowerCase());
      });
    }


    /* Ovde sada nastavljamo da filtriramo samo filteredEvents events  */
    if (this.chosenTags && this.chosenTags.length > 0) {
      filteredEvents = filteredEvents.filter(timelineEvent => {
        //console.log('filtriram event :' + timelineEvent.title + ', sa licnosti tag:' + timelineEvent.licnostiTags);
        let hasAllTags = true;
        this.chosenTags.forEach(tag =>
          hasAllTags = hasAllTags &&
            ((timelineEvent.institucijaTags && timelineEvent.institucijaTags.findIndex(instTag => instTag === tag.tagName) >= 0) ||
              (timelineEvent.licnostiTags && timelineEvent.licnostiTags.findIndex(licTag => {
                // console.log('poredim licnosti:' + licTag + '===' + tag.tagName + ', rez:'
                // + (licTag === tag.tagName) + ', za dogadjaj:' + timelineEvent.title);
                return (licTag === tag.tagName);
              }) >= 0) ||
              (timelineEvent.ostaloTags && timelineEvent.ostaloTags.findIndex(ostTag => ostTag === tag.tagName) >= 0) ||
              (timelineEvent.siriTags && timelineEvent.siriTags.findIndex(siriTag => siriTag === tag.tagName) >= 0)
            ));
        return hasAllTags;
      });
      console.log('Po tagovima ima dogadjaja:' + filteredEvents.length);
    }

    this.filteredTimelineEvents = new Array().concat(filteredEvents);
    this.filteredTimelineEvents.sort(this.compareDates);
    console.log('filter events NUM:' + this.filteredTimelineEvents.length + '==' + JSON.stringify(this.filteredTimelineEvents));
    if (this.filteredTimelineEvents && this.filteredTimelineEvents.length > 0) {
      console.log('bbbbbbbbbbbbbbbbbbbb: ', this.filteredTimelineEvents[0].date);
      this.startDate = this.filteredTimelineEvents[0].date;
      if (this.filteredTimelineEvents.length === 1) {
        const d: string = this.timelineEvents[0].date.getUTCDate() + '-' +
          (this.timelineEvents[0].date.getUTCMonth() + 1) + '-' + (this.timelineEvents[0].date.getFullYear() + 1);
        console.log('DATE STR:' + d);
        this.endDate = moment(d, 'DD-MM-YYYY').add(6, 'M').toDate();
        console.log('added amount: ' + this.endDate);
      } else {
        this.endDate = this.filteredTimelineEvents[this.filteredTimelineEvents.length - 1].date;
      }
      if (this.filteredTimelineEvents.length <= 3) {

      }
      console.log('start date:' + this.startDate);
      console.log('end date:' + this.endDate);
      this.izracunajBrojDanaIVelicinu();
      this.calculateTimeLineEventsAttributes();
      //this.showEventsByRelevanceAndDensity();
      this.fillSignificansList(this.filteredTimelineEvents);
      this.initViewForSelectedEvent('-1');
    } else {
      this.timelineEvents = [];
      this.hiddentTimelineEvents = [];
    }
    this.landingPage = false;
  }


  /*  Ovo je deo u kome se obradjuje scroll timeline pan dogadjajem   */
  panStarted(event: any) {
    this.panStartedX = event.center.x;
    console.log('PAN started!!!');
  }

  panEnded(event: any) {
    //this.leftScroll += this.panStartedX - event.center.x;
  }


  getBackgroundImUrl(mediaSource: MediaSource): string {
    return 'url(\"' + mediaSource.fullLink + '\")';
  }

  /* TODO:  Ovu metodu je potrebno ukloniti  */
  addTagToFilter(tag: string) {
    console.log('TAG SE DODAJE!' + tag);
    // const ind = this.allMediaTags.findIndex(mediaTag => mediaTag.tagName === tag);
    // if (ind >= 0) {
    //   this.chosenTags.push(this.allMediaTags[ind]); // ovo se moze izbaciti
    this.timelineFilterService.externalFilterDataHasChanged(
      new FilterDataChanged(
        FilterDataChangedEvent.TAG_ADDED,
        {
          tag: tag
        })
    );
    // this.filterAllTimelineEvents(); Sada se samo prebacuju tagovi u filter i tek onda korisnik treba da pritisne primeni.
    // }
  }

  sreditimeLinePrikaz() {
    /*  Ovo je moralo ovako jer timeline nije uzeo u obzir leftscroll dok se ne izmeni.
    Moguce da je kreirao taj kontejner sa nultom sirinom pa ona nije taj leftScroll bio dobar ?  */
    const t = timer(100);
    t.subscribe(time => this.leftScroll++);
  }

  significanceLevelChangedEvent(event: any) {
    this.selectedSignificanceLevel = event.zoomLevel;
    this.significanceLevelChanged(-1);
  }

  timelineEventSelectedOnVerticalTimeline(event: any) {
    this.selectedEventID = event.timelineEvent.id;
    this.selectedEvent = event;
    this.landingPage = false;
    this.stateManagementService.preserveState('selected_event', event.timelineEvent);
    if (this.deviceDetector.isMobile()) {
      this.router.navigate(['event-detail/', this.selectedEventID]);
    }

    this.timelineEventSelected(event.timelineEvent, event.index);
    this.showEventInfoMobile = true;
    this.mobileViewMode = MobileViewMode.EVENT_DETAILS;
    this.questionnaireService.increaseNoOfSelectedEvents();
    if (this.questionnaireService.shouldShowQuestionnaireAfterEventSelection()) {
      this.showQuestionnaireDialog();
    }
  }

  onSwipeLeft(event: any) {
    this.showEventInfoMobile = false;
  }

  onSwipeRight(event: any) {
    console.log('swipe right');
  }

  zatvoriPrikazDogadjaja() {
    if (this.mobileViewMode === MobileViewMode.EVENT_DETAILS) {
      this.showEventInfoMobile = !this.showEventInfoMobile;
    } else {
      this.mobileViewMode = MobileViewMode.EVENT_DETAILS;
    }
  }

  onYearSelection(event: any) {
    this.dayLength = event.dayLength;
    this.leftScroll = event.leftScroll;
  }

  private subscribeToFilterChanges() {
    if (this.deviceDetector.isDesktop()) {
      this.timelineFilterService.filterDataChangedSubject.subscribe(
        newFilterData => {
          console.log('NEW FILTER DATA IS HERE:', newFilterData);
          this.setNewFilterData(newFilterData);
          this.filterAllTimelineEvents();
        }
      );
    }
  }

  private setNewFilterData(newFilterData: any) {
    this.chosenCategories = newFilterData.chosenCategories;
    this.filteredStartDate = newFilterData.filteredStartDate ? newFilterData.filteredStartDate : this.filteredStartDate;
    this.filteredEndDate = newFilterData.filteredEndDate ? newFilterData.filteredEndDate : this.filteredEndDate;
    this.filterEventName = newFilterData.filterEventName;
    this.chosenTags = newFilterData.chosenTags;
  }

  private preserveState() {
    this.stateManagementService.preserveState('TIMELINE_STATE',
      {
        timelineEvents: this.timelineEvents,
        dataLoading: this.dataLoading,
        allTimelineEvents: this.allTimelineEvents,
        filteredTimelineEvents: this.filteredTimelineEvents,
        startDate: this.startDate,
        endDate: this.endDate,
        leftScroll: this.leftScroll,
        dayLength: this.dayLength,
        selectedEventID: this.selectedEventID,
        selectedOnDayEvent: this.selectedOnDayEvent,
        timelineSize: this.timelineSize,
        majorLines: this.majorLines,
        minorLines: this.minorLines,
        significanceLevels: this.significanceLevels,
        selectedSignificanceLevel: this.selectedSignificanceLevel
      });
  }

  private readState() {
    const timelineState = this.stateManagementService.getState('TIMELINE_STATE');
    if (timelineState) {
      this.timelineEvents = timelineState.timelineEvents;
      this.dataLoading = timelineState.dataLoading;
      this.allTimelineEvents = timelineState.allTimelineEvents;
      this.filteredTimelineEvents = timelineState.filteredTimelineEvents;
      this.startDate = timelineState.startDate;
      this.endDate = timelineState.endDate;
      this.leftScroll = timelineState.leftScroll;
      this.dayLength = timelineState.dayLength;
      this.selectedEventID = timelineState.selectedEventID;
      this.selectedOnDayEvent = timelineState.selectedOnDayEvent;
      this.timelineSize = timelineState.timelineSize;
      this.majorLines = timelineState.majorLines;
      this.minorLines = timelineState.minorLines;
      this.significanceLevels = timelineState.significanceLevels;
      this.selectedSignificanceLevel = timelineState.selectedSignificanceLevel;
    }
  }

  private prepareDataForView(evtID: string, data: any = null) {
    console.log('--------prepareDataForView:', moment().format('h:mm:ss'));
    this.selectedEventID = evtID;
    console.log('EVENT ID:' + this.selectedEventID);
    console.log('URL:', this.router.url);
    console.log('URL - ACTIVE ROUTE:', this.activeRoute.snapshot.url);
    console.log('URL - WINDOW:', window.location.href);
    this.httpTimelineservice.setEndpointPathByUrl(window.location.href);
    if (!this.allTimelineEvents || this.allTimelineEvents.length === 0) {
      this.makeAllTimeLineEvents(data);
      this.onDayEvents = [...this.timelineeventUtilService.getOnTodaysDateEvents(this.allTimelineEvents)];
      const indOnDayEvent = Math.round(Math.random() * (this.onDayEvents.length - 1));
      if (indOnDayEvent >= 0) {
        this.selectedOnDayEvent = this.onDayEvents[indOnDayEvent];
        this.selectedEvent = this.selectedOnDayEvent.timelineEvent;
        //this.timelineEventSelected(this.selectedOnDayEvent.timelineEvent, -1);
        // this.vratiPodatkeIzabranogDogadjaja(this.selectedOnDayEvent.timelineEvent);
        const timerSourcesInfo = timer(1000);
        timerSourcesInfo.subscribe(val => this.showSourcesInformation = true);
        //this.ucitajPodatkeZaSveIzvore(this.selectedOnDayEvent.timelineEvent);
      }

      console.log('on days events: ', this.onDayEvents);
    }
    if (evtID === '0') {
      console.log('POSTAVLJAM LANDING PAGE NA TRUE');
      if (this.zanimljivostiCat.selected) {
        /*  treba resetovati na prethodnu listu */
        // console.log('temp list: ', ...this.allTimelineeventsTemp);
        this.allTimelineEvents = [...this.allTimelineeventsTemp];
        this.filteredTimelineEvents = [...this.fiteredTimelineeventsTemp];
        this.resetSignificanceLevel();
      }
      this.landingPage = true;
    } else {
      // Ako je izabrana kategorija zanimljivosti onda se prebace dogadjaji iz zanimljivosti u all timeEvents
      if (evtID === '-5') {
        this.landingPage = false;
        console.log('EVENT ID ZANIMLJIVOSTI:' + this.zanimljivostiTimeLineEvents.length);
        this.resetAllFilters();
        this.zanimljivostiCat.selected = true;
        this.allTimelineeventsTemp = [...this.allTimelineEvents];
        this.fiteredTimelineeventsTemp = [...this.filteredTimelineEvents];
        this.allTimelineEvents = [...this.zanimljivostiTimeLineEvents];
        this.filteredTimelineEvents = [...this.zanimljivostiTimeLineEvents];
        this.resetSignificanceLevel();
        // console.log('ZANIMLJIVOSTI timeline:', this.allTimelineEvents);

      }
      this.landingPage = false;
      const ind = this.allTimelineEvents.findIndex(tmlevt => tmlevt.id === evtID);
      if (ind >= 0) {
        //this.timelineEventSelected(this.timelineEvents[ind], ind);
        this.selectedEvent = this.allTimelineEvents[ind];
        this.selectedEventID = this.allTimelineEvents[ind].id;
      }
    }

    if (evtID === '0' && this.selectedOnDayEvent) {
      this.selectedEvent = this.selectedOnDayEvent.timelineEvent;
    }

    console.log('--------prepareDataForView - init data:', moment().format('h:mm:ss'));
    if (data) {
      this.initData();
      this.initViewForSelectedEvent(evtID);
    }

    console.log('--------prepareDataForView - filtering mobile:', moment().format('h:mm:ss'));
    if (this.timelineFilterService.filterAppliedMobile) {
      console.log('FILTERING THE DATA');
      this.filterAllTimelineEvents();
    }
    console.log('--------prepareDataForView - END:', moment().format('h:mm:ss'));
    this.dataLoading = false;
    this.changeDetectorRef.detectChanges();
  }

  private resetData() {
    this.timelineEvents = new Array();
    this.allTimelineEvents = new Array();
    this.filteredTimelineEvents = new Array();
    this.significanceLevelEventCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // ima 10 nivoa znacajnosti + 0
    this.resetSignificanceLevel();
  }

  get testTimelineEvents(): TimelineEvent[] {
    return this.timelineEvents.slice(0 , 3);
  }
}
