

export class MediaTag {

  id: string;
  tagType: string;
  tagName: string;


}
