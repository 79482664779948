import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {StateManagementService} from '../services/state-management.service';

@Injectable({
  providedIn: 'root'
})
export class EventDetailsGuard implements CanActivate {
  constructor(private router: Router, private stateManagementService: StateManagementService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const selectedEvent = this.stateManagementService.getState('selected_event');
    if (!selectedEvent) {
      this.router.navigate(['timeline', 0]);
      return false;
    }

    return true;
  }
}
