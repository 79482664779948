import {Component, Input, OnInit} from '@angular/core';
import {YearEvent} from '../domain/view/YearEvent';

@Component({
  selector: 'app-yearly-events',
  templateUrl: './yearly-events.component.html',
  styleUrls: ['./yearly-events.component.css']
})
export class YearlyEventsComponent implements OnInit {

  @Input() listOfYearEvents: YearEvent[];

  @Input() showYearATabs = true;
  constructor() { }

  ngOnInit() {
  }

}
