import {SafeStyle} from '@angular/platform-browser';

export class MediaSource {
  id: string;
  sourceInstitutionName: string;
  tip?: string;
  autor?: string;
  sourceType: string; /* postojace sledece opcije IMAGE, VIDEO, PDF*/
  fileName: string;
  fullLink: string;
  sanitizedLink: SafeStyle ;
  newLink: string;
  dataloaded = false;
  imageLoaded = false;
  // Dodato 18.02.2018, zato sto je mogu'e da ce doci linkovi sa greskom, po dogovoru sa Saletom
  wrongLink = false;
  thumbLink?: string;
  detailDataLoaded = false;


}
