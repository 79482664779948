import {MediaTag} from './MediaTag';
import {TimelineEvent} from './TimelineEvent';

export class TeaserQuestion {
  mediaTags: MediaTag[];
  teaserQuestion: string;
  eventList: TimelineEvent[];

  constructor(mediaTag: MediaTag, teaserQuestion: string) {
    this.mediaTags = [];
    this.mediaTags.push(mediaTag);
    this.teaserQuestion = teaserQuestion;
    this.eventList = [];
  }


}
