export class QuestionaryModel {
  pol: string;
  godine: string;
  razlog: string;
  medijskiMaterijalKvalitetan?: string;
  daLiBisteKoristiliMaterijal?: string;
  medij: string;
  problemi: string;
  email: string;
}
