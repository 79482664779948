import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimelineFilterService {

  private filterDataChanged: Subject<any> = new Subject();
  private externalFilterDataChanged: Subject<FilterDataChanged> = new Subject();

  private _filterApplyed: Subject<any> = new Subject();
  /**
   * Ovo se koristi za mobilnu aplikaciju zato što su filteri odvojeni kao posebne rute.
   **/
  filterAppliedMobile = false;
  lastAppliedFilter: any;


  constructor() {
  }

  filterDataHasChanged(filterData: any) {
    this.filterAppliedMobile = true;
    this.lastAppliedFilter = filterData;
    this.filterDataChanged.next(filterData);
  }

  get filterDataChangedSubject(): Subject<any> {
    return this.filterDataChanged;
  }

  get extrernalFilterDataChangedSubject(): Subject<any> {
    return this.externalFilterDataChanged;
  }

  get filterApplyed(): Subject<any> {
    return this._filterApplyed;
  }

  externalFilterDataHasChanged(filterData: any) {
    console.log('EXTERNAL DATA CHANGE:');
    this.externalFilterDataChanged.next(filterData);
  }
}


export class  FilterDataChanged {

  constructor(public changeEvent: FilterDataChangedEvent, public changedData: any) { }

}

export enum FilterDataChangedEvent {
  DATE_CHANGED, TAG_ADDED
}

export enum FilterType {
  TAG, RUBRIKA, NAZIV_DOGADJAJA, DATUM
}
