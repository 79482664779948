import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {TimelineEvent} from '../domain/TimelineEvent';
import {FormControl} from '@angular/forms';

export interface DialogData {
  timelineEvent: TimelineEvent;
  selectedMediaSourceNum: number;
}

@Component({
  selector: 'app-dialogs',
  templateUrl: './dialogs.component.html',
  styleUrls: ['./dialogs.component.css']
})
export class DialogsComponent implements OnInit {

  activeMediaSourceNum: number;
  zoomValue = 100;

  zoomValueCtrl = new FormControl();

  constructor( public dialogRef: MatDialogRef<DialogsComponent>,
               @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.activeMediaSourceNum = data.selectedMediaSourceNum;
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ucitanaSlika(event: any) {
    const imageContainer = document.getElementById('imageContainer');
    //const imageDiv: HTMLImageElement = document.getElementById('imageSrc');
    //alert('slika je ucitana' + imageDiv.naturalWidth + ', div container'  + imageContainer.clientWidth);
    //this.zoomValue = (imageDiv.naturalWidth / imageContainer.clientWidth) * 100;
    //this.zoomValue = this.zoomValue> 100 ? 100 : this.zoomValue;
  }

  loadComplete(pdf: any) {
    console.log('Load is completed');
  }

}

