import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-use-timeline',
  templateUrl: './how-to-use-timeline.component.html',
  styleUrls: ['./how-to-use-timeline.component.css']
})
export class HowToUseTimelineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
