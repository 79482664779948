import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TeaserQuestion} from '../domain/teaser-question';
import {MediaTag} from '../domain/MediaTag';
import {TimelineEvent} from '../domain/TimelineEvent';
import {timer} from 'rxjs';

@Component({
  selector: 'app-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.css']
})
export class TeaserComponent implements OnInit {

  teaserQuestions: TeaserQuestion[] = [];
  chosenQuestions: TeaserQuestion[] = [];
  showTeaserQuestion = false;
  @Input() allTimelineEvents: TimelineEvent[];

  @Output() teaserTimelineSelected: EventEmitter<MediaTag[]> = new EventEmitter();

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnInit() {

    const t = timer(10000);
    t.subscribe(time => {
        this.showTeaserQuestion = true;
      }
    );
    this.teaserQuestions.push(new TeaserQuestion({
      'id': 'FB5BA34DFF6682D0C125832B0031FDD3',
      'tagType': 'Ostalo',
      'tagName': 'vaterpolo'
    }, 'Da li znate u koliko događaja se pominje naša vaterpolo reprezentacija?'));
    this.teaserQuestions.push(new TeaserQuestion({
      'id': '45DA8A1907CCF49FC12582AD003A68FF',
      'tagType': 'Licnost',
      'tagName': 'Slobodan Milošević'
    }, 'Da li znate u koliko događaja se pominje Slobodan Milošević?'));
    this.teaserQuestions.push(new TeaserQuestion({
      'id': '5BEBE9510E3B0EF3C12582DB0035FD6B',
      'tagType': 'Licnost',
      'tagName': 'Zoran Đinđić'
    }, 'Da li znate u koliko događaja se pominje Zoran Đinđić?'));
    this.teaserQuestions.push(new TeaserQuestion({
      'id': '6LE5E9510E3Y2EF3C12582DB0035DI7Q',
      'tagType': 'Institucije',
      'tagName': 'Savet bezbednosti UN (SB UN)'
    }, 'Da li znate u koliko događaja se pominje Savet bezbednosti Ujedinjenih nacija?'));

    this.selectRandomQuestion();

  }

  selectRandomQuestion() {
    this.chosenQuestions = [];
    this.chosenQuestions.push(this.teaserQuestions[Math.round(Math.random() * (this.teaserQuestions.length - 1))]);
  }

  teaserQuestionSelected(teaserQuestion: TeaserQuestion) {
    this.teaserTimelineSelected.emit(teaserQuestion.mediaTags);
    const eventList = this.allTimelineEvents.filter(timelineEvent => {
      let hasAllTags = true;
      teaserQuestion.mediaTags.forEach(tag =>
        hasAllTags = hasAllTags &&
          ((timelineEvent.institucijaTags && timelineEvent.institucijaTags.findIndex(instTag => instTag === tag.tagName) >= 0) ||
            (timelineEvent.licnostiTags && timelineEvent.licnostiTags.findIndex(licTag => {
              return (licTag === tag.tagName);
            }) >= 0) ||
            (timelineEvent.ostaloTags && timelineEvent.ostaloTags.findIndex(ostTag => ostTag === tag.tagName) >= 0) ||
            (timelineEvent.siriTags && timelineEvent.siriTags.findIndex(siriTag => siriTag === tag.tagName) >= 0)
          ));
      return hasAllTags;
    });
    if (eventList && eventList.length > 0) {
      const period = Math.floor(100);
      console.log('PERIOD:' + period);
      const t = timer(50, period);
      let i = 1;
      const increment = Math.floor(eventList.length / 15);
      // eventList.forEach(evt => teaserQuestion.eventList.push(evt));
      const inter = setInterval(time => {
          teaserQuestion.eventList.push(eventList[i]);
          if (i >= eventList.length - 1) {
            clearInterval(inter);
            timer(2000).subscribe( t => this.showTeaserQuestion = false);
          } else {
            i++;
          }
        },
        50);

      // const timerSubscription = t.subscribe(time => {
      //   teaserQuestion.eventList.push(eventList[i]);
      //   //i + increment < eventList.length ? i += increment : i = eventList.length - 1;
      //
      //   this.ref.detectChanges();
      //   if (i === eventList.length) {
      //     console.log('UNSUBSCRIBE!!!' + i );
      //     timerSubscription.unsubscribe();
      //   }
      //   i++;
      // });

    }

    console.log('TEASER LIST:' + teaserQuestion.eventList.length);
  }

}
