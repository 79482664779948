import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottom-sheet-filter',
  templateUrl: './bottom-sheet-filter.component.html',
  styleUrls: ['./bottom-sheet-filter.component.css']
})
export class BottomSheetFilterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
