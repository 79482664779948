import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {timer} from 'rxjs';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  @Output() povratakTajmlajn: EventEmitter<any> = new EventEmitter();

  i = 0;

  constructor() { }

  ngOnInit() {

  }


  otvoriTajmlajn() {
    this.povratakTajmlajn.emit({povratak: true});
  }

}
