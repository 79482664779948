import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import {TimelineEvent} from '../domain/TimelineEvent';
import {StateManagementService} from '../services/state-management.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-izvor-detalji',
  templateUrl: './izvor-detalji.component.html',
  styleUrls: ['./izvor-detalji.component.css']
})
export class IzvorDetaljiComponent implements OnInit, AfterViewInit {

  @Input() timelineEvent: TimelineEvent;
  @Input() activeMediaSourceNum: number;
  zoomValue = 100;
  screenWidth;

  constructor(private stateManagementService: StateManagementService, private deviceDetector: DeviceDetectorService) { }

  ngOnInit() {
    if (!this.timelineEvent || this.deviceDetector.isMobile()) {
      this.timelineEvent = this.stateManagementService.getState('selected_event');
      this.activeMediaSourceNum = this.stateManagementService.getState('ACTIVE_MEDIA_SOURCE_NUM');
    }
  }

  ngAfterViewInit() {
    this.screenWidth =  window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {

    this.screenWidth = window.innerWidth;
  }

}
