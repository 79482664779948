import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd} from '@angular/router';
import {
  MatBottomSheet, MatDialog,
  MatIconRegistry
} from '@angular/material';
import {TimelineEventService} from './services/timeline-event.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {SiteOrigin} from './shared/enums/enums';
import {DeviceDetectorService} from 'ngx-device-detector';
import {MediaMatcher} from '@angular/cdk/layout';
import {log} from 'util';
import {BottomSheetFilterComponent} from './bottom-sheet-filter/bottom-sheet-filter.component';
import {TimelineFilterService} from './services/timeline-filter.service';
import {Subscription} from 'rxjs';
import {QuestionnaireModalComponent} from './questionary/questionnaire-modal.component';
import set = Reflect.set;

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  siteOrigin: SiteOrigin = SiteOrigin.SRBIJA;
  _siteOrigins = SiteOrigin;
  bootomSheetOpened = false;
  openFilterPopover = false;
  filterOpened = false;
  subscriptions: Subscription;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer, private activeRoute: ActivatedRoute,
              private httpTimelineservice: TimelineEventService, public deviceDetector: DeviceDetectorService, public router: Router,
              changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private _bottomSheet: MatBottomSheet, public dialog: MatDialog,
              private timelineFilterService: TimelineFilterService) {
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-167523280-1',
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      }
    );


    iconRegistry.addSvgIcon(
      'my-remove-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icon-delete-small-hover.png'));
    console.log('IS MOBILE:' + this.deviceDetector.isMobile());
    this.mobileQuery = media.matchMedia('(max-width: 600px)');

    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);


  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.subscriptions.unsubscribe();
  }

  toggleBottomSheet(): void {
    if (!this.deviceDetector.isMobile()) {
      return;
    }
    if (!this.bootomSheetOpened) {
      this._bottomSheet.open(BottomSheetFilterComponent);
      this.bootomSheetOpened = true;
    } else {
      this._bottomSheet.dismiss();
      this.bootomSheetOpened = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.openFilterPopover = true;
      console.log('Open popover');
    }, 4000);

    setTimeout(() => {
      this.openFilterPopover = false;
    }, 6000);

  }


  ngOnInit() {

    this.activeRoute.paramMap.subscribe(
      (data) => {
        const url = window.location.href;
        if (url && url.indexOf('cacak') >= 0) {
          this.siteOrigin = SiteOrigin.CACAK;
        } else {
          this.siteOrigin = SiteOrigin.SRBIJA;
        }
        this.siteOrigin = this.httpTimelineservice.setEndpointPathByUrl(window.location.href);
      }
    );



    // this.subscriptions = this.timelineFilterService.filterApplyed.subscribe(
    //   () => {
    //     this.toggleBottomSheet();
    //   }
    // );

  }


  filterChanged(event: any) {
    console.log('FILTER OUTPUT EVENT');
    this.filterOpened = false;
  }


  openFilterPage() {
    this.router.navigateByUrl('/timeline-filter');
  }

  public async openQuestionaryDialog() {
    const dialogRef = this.dialog.open(QuestionnaireModalComponent, {
      width: '30%',
      height: '70%'
    });
    await dialogRef.afterClosed().toPromise();
  }

}
