import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  noOfSelectedEvents = 0;


  constructor() {
  }

  get storage() {
    return localStorage;
  }

  increaseNoOfSelectedEvents(): void {
    ++this.noOfSelectedEvents;
  }

  shouldShowQuestionnaireAfterEventSelection(): boolean {
    return !this.questionnaireSubmitted && !this.questionnaireCanceled && environment.showQuestionnaireAfter <= this.noOfSelectedEvents;
  }

  markQuestionnaireAsSubmitted(): void {
    this.storage.setItem('questionnaireSubmitted', String(true));
  }

  markQuestionnaireAsCanceled(): void {
    this.storage.setItem('questionnaireCanceled', String(true));
  }

  get questionnaireSubmitted(): boolean {
    return !!this.storage.getItem('questionnaireSubmitted');
  }

  get questionnaireCanceled(): boolean {
    return !!this.storage.getItem('questionnaireCanceled');
  }

}
