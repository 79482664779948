import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DialogData} from '../dialogs/dialogs.component';
import {TimelineEventService} from '../services/timeline-event.service';
import {RazlogValues} from '../domain/enums';
import {QuestionaryModel} from '../domain/QuestionaryModel';

@Component({
  selector: 'app-questionary',
  templateUrl: './questionnaire-modal.component.html',
  styleUrls: ['./questionnaire-modal.component.css']
})
export class QuestionnaireModalComponent implements OnInit {

  questionnaireModel: QuestionaryModel;

  mladjiModel: any;

  constructor(public dialogRef: MatDialogRef<QuestionnaireModalComponent>, private httpTimelineservice: TimelineEventService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.questionnaireModel = new QuestionaryModel();
  }

  ngOnInit() {
  }

  closeQuestionnaire() {
    this.dialogRef.close();
  }

  saveQuestionnaire() {
    console.log('Questionary: ', this.questionnaireModel);
    this.httpTimelineservice.putQuestionaryResults(this.questionnaireModel).subscribe(
      res => {
        if (res.status === 200 ) {
          this.dialogRef.close({questionnaireSubmitted: true});
        }
      }
    );
  }

  onRazlogValueChange(razlogValue: any) {
    if (razlogValue && Number(razlogValue) !== RazlogValues.Novinarsko_istrazivanje) {
      this.questionnaireModel.medijskiMaterijalKvalitetan = undefined;
      this.questionnaireModel.daLiBisteKoristiliMaterijal = undefined;
    }
  }
}


// web.arhiv.rs/Develop/Timelineq.nsf/putQuerry?OpenAgent&godine=test&razlog=test&medij=test&problemi=test



