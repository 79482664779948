import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TimelineEvent} from '../domain/TimelineEvent';
import {TimelineUtilService} from '../services/timeline-util.service';

import * as Hammer from 'hammerjs';
import {SignificanseEventInfo} from '../domain/SignificanseEventInfo';
import {LineInfo} from '../domain/LineInfo';
import * as moment from 'moment';

@Component({
  selector: 'app-vertical-timeline',
  templateUrl: './vertical-timeline.component.html',
  styleUrls: ['./vertical-timeline.component.css']
})
export class VerticalTimelineComponent implements OnInit, AfterViewInit {

  @Input() set timelineEvents(timelineEvents: TimelineEvent[]) {
    this._timelineEvents = timelineEvents;
    this.setYearRangAndScrollPosition();
  }
  private _timelineEvents: TimelineEvent[];
  private _leftScroll: number;
  private _dayLength: number;

  private _firstVisibleYear: number;
  private _lastVisibleYear: number;

  get timelineEvents(): TimelineEvent[] {
    return this._timelineEvents;
  }

  @Input() dataLoading: boolean;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() set leftScroll(leftScroll: number) {
    this._leftScroll = leftScroll;
    this.setYearRangAndScrollPosition();
  }
  @Input() set dayLength( dayLength: number) {
    this._dayLength = dayLength;
    this.setYearRangAndScrollPosition();
  }
  @Input() majorLines: LineInfo[];
  @Input() minorLines: LineInfo[];
  @Input() selectedEventID: string;
  @Output() contenetMoved = new EventEmitter();
  @Output() timelineEventSelected = new EventEmitter();
  @Output() zoomOut = new EventEmitter();
  @Output() zoomIn = new EventEmitter();
  @Output() yearSelection = new EventEmitter();
  @Input() timelineSize: number;

  selectedSignificanceLevel: SignificanseEventInfo;
  @Output() zoomLevelChanged = new EventEmitter();
  @Input() set significanceLevels(significanceLevels: SignificanseEventInfo[]) {
    this._significanceLevels = significanceLevels;
    if (this._currentSigLevel !== undefined && this._currentSigLevel !== null) {
      this.selectedSignificanceLevel = this._significanceLevels.find(value => value.siginficanseLevel === this._currentSigLevel);
    }
  }
  @Input() set currentSigLevel(currentSigLevel: number) {
    this._currentSigLevel = currentSigLevel;
    if (this.significanceLevels) {
      this.selectedSignificanceLevel = this.significanceLevels.find(value => value.siginficanseLevel === this._currentSigLevel);
    }

  }
  private _significanceLevels: SignificanseEventInfo[];
  private _currentSigLevel: number;
  firstEventDate: Date;
  lastEventDate: Date;
  startYear: number;
  endYear: number;

  @ViewChild('movableTimelineID') movableTimelineDiv: ElementRef;
  numOfLanes = 2;
  clientY = 0;
  windowHeight;
  windowWidth;
  eventName: string = 'NO EVENTES';

  showPopover: boolean[] = [false, false, false, false, false, false];

  constructor(private timelineUtilService: TimelineUtilService) {
    console.log('VERT TIMELINE CONSTRUCTOR!');
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
    if (this.significanceLevels) {
      this.selectedSignificanceLevel = this.significanceLevels.find(value => value.siginficanseLevel === this._currentSigLevel);
    }
  }


  get significanceLevels(): SignificanseEventInfo[] {
    return this._significanceLevels;
  }

  get currentSigLevel(): number {
    return this._currentSigLevel;
  }

  get leftScroll(): number {
    return this._leftScroll;
  }

  get dayLength(): number {
    return this._dayLength;
  }


  get firstVisibleYear(): number {
    return this._firstVisibleYear;
  }

  get lastVisibleYear(): number {
    return this._lastVisibleYear;
  }

  ngAfterViewInit() {
    // this.eventName = 'PINCH will be ENABLED!!!';
    // const hammer = new Hammer(this.movableTimelineDiv.nativeElement);
    // hammer.get('pinch').set({enable: true});
    // this.eventName = 'PINCH ENABLED!!!';
    let ind = 0;
    if (!this.timelineUtilService.popoversDisplayed) {
      const interval = setInterval( () => {
        if ( ind > 0 ) {this.showPopover[ind - 1] = false; this.timelineUtilService.popoversDisplayed = true;};
        if (ind === this.showPopover.length) { clearInterval(interval); }
        else { this.showPopover[ind] = true; };
        ind++;
      }, 2000);
    }

  }

  moveContenet(event: any) {
    console.log('CONETENT MOVED - VERTICAL');
    //event.stopPropagation();
    this.contenetMoved.emit(event);
  }

  inBetween(startDate: Date, endDate: Date) {
    return this.timelineUtilService.inBetween(startDate, endDate);
  }

  timelineEventSelectedClick(timelineEvent: TimelineEvent, index: number) {
    this.selectedEventID = timelineEvent.id;
    this.timelineEventSelected.emit({
      timelineEvent: timelineEvent,
      index: index
    });
  }


  touchMe(event: any) {
    this.eventName = 'Touch move';
    const moveY = event.changedTouches[0].clientY - this.clientY;
    this.clientY = event.changedTouches[0].clientY;
   // event.stopPropagation();
    this.contenetMoved.emit({
      moveIncrement: 0,
      verticalIncrement: moveY
    });
  }

  touchStart(event: any) {
    console.log('Touch start', event);
    this.clientY = event.touches[0].clientY;
  }

  touchEnd(event: any) {
    console.log('Touch end', event);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  }

  zooming = false;

  onPinchIn(event) {
    this.eventName = 'PINCH innnnnnn';
    //this.zoomOut.emit({event: 'zoom-out'});
   this.onZoomOut();
  }

  evt: any;
  onPinchOut(event) {
    this.evt = event;
    this.eventName = 'PINCH out';
    this.onZoomIn();
  }

  onSwipeLeft(event) {
    this.eventName = 'SWIPE LEFT';
  }

  onSwipeRight(event) {
    this.eventName = 'SWIPE RIGHT';
  }

  onZoomOut() {
    if (this.zooming) return;
    console.log('ZOOMING OUT:', this.dayLength);
    this.zooming = true;
    this.zoomOut.emit({event: 'zoom-out'});
    setTimeout( () => this.zooming = false, 500);
  }

  onZoomIn() {
    if (this.zooming) return;
    console.log('ZOOMING in:', this.dayLength);
    this.zooming = true;
    this.zoomIn.emit({event: 'zoom-in'});
    setTimeout( () => this.zooming = false, 500);
  }

  noOfVisibleEvents = 0;
  noOfAllEvents = 0;
  significanceLevelChanged() {
    console.log('SIGNIFICANCE LEVEL');
    this.noOfVisibleEvents = this.selectedSignificanceLevel ? this.selectedSignificanceLevel.noOfEvents : 0;
    this.noOfAllEvents = this.significanceLevels && this.significanceLevels.length ? this.significanceLevels[this.significanceLevels.length - 1].noOfEvents : 0;
    this.zoomLevelChanged.emit({ zoomLevel: this.selectedSignificanceLevel.siginficanseLevel});
  }

  yearDivHeight = 100;
  yearsInView = [];
  positionFromFirstYear = 0;
  displayedRangeDivHeight= 0;
  private setYearRangAndScrollPosition() {
    console.log('this.positionFromFirstYear:', this.windowHeight);
    if (this._timelineEvents.length > 0) {
      this.firstEventDate = this._timelineEvents[0].date;
      this.lastEventDate = this._timelineEvents[this._timelineEvents.length - 1].date;
      //TODO: Ako su datumi isti onda dodaj par dana za lastevent date
      // console.log('Last event calculating', this.lastEventDate.getFullYear(), this.lastEventDate);
      this.startYear = this.firstEventDate.getFullYear();
      this.endYear = this.lastEventDate.getFullYear();
      this.yearDivHeight = (this.windowHeight - 160) / (this.endYear - this.startYear + 1);
      this.yearsInView  = Array(this.endYear - this.startYear + 1).fill(this.startYear ).map((_, i) => this.startYear + i);
      console.log('years:', this.yearsInView);
      this.calculateVisibleRangePosition();
    }
  }

  calculateVisibleRangePosition() {
    console.log('this.positionFromFirstYear', this.startDate);
    let firstVisibleDay = this.startDate ? this.startDate : new Date();
    //console.log('this.positionFromFirstYear', this.leftScroll, this.dayLength, (-1) * this.leftScroll / this.dayLength, firstVisibleDay.getDate());
    // Number of days from start date
    firstVisibleDay  = moment(firstVisibleDay).add(Math.round( (-1) * this.leftScroll / this.dayLength) + 3,'days').toDate() ;
    const lastVisibleDay: Date = moment(firstVisibleDay).add(Math.round( (this.windowHeight - 160) / this.dayLength) - 5,'days').toDate();
    this._firstVisibleYear = firstVisibleDay.getFullYear();
    this._lastVisibleYear = lastVisibleDay.getFullYear();
    console.log('first visible dat', firstVisibleDay);
    this.positionFromFirstYear = (this.yearDivHeight / 365) * this.inBetween(
      moment('01-01-' + this.startYear, 'DD-MM-YYYY').toDate(), firstVisibleDay);
    console.log('this.positionFromFirstYear', this.positionFromFirstYear, this.yearDivHeight);
    this.displayedRangeDivHeight = ((this.windowHeight - 160) / this.dayLength) * (this.yearDivHeight / 365);
    console.log('firstVisible year:', this._firstVisibleYear, 'last visible year:', this._lastVisibleYear);
  }


  showEventsOfTheYear(year: number) {

    const firstdayOfYear: Date = moment('01-01-' + year, 'DD-MM-YYYY').toDate();
    console.log('year selection:', year , firstdayOfYear, this.dayLength, this.leftScroll)
    const dayLength = (this.windowHeight - 160) / 365;
    const leftScroll = this.inBetween(firstdayOfYear, this.startDate) * dayLength;
    console.log('year selection - calc:' + dayLength, leftScroll)
    this._firstVisibleYear = year;
    this._lastVisibleYear = year;
    console.log('firstVisible year:', this._firstVisibleYear, 'last visible year:', this._lastVisibleYear);
    this.yearSelection.emit({leftScroll: leftScroll, dayLength: dayLength });
  }


}
